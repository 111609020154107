import { Row, Col, Container } from "react-bootstrap";

export function Footer() {
  return (
    <div className="fmv-footer">
      <Container fluid className="fmv-page-footer">
        <div className="fmv-footer-container">
          <Row className="align-items-center">
            <Col md={2}></Col>
            <Col md={8} className="text-center">
              <span className="fmv-footer-text">
                This site contains links to websites to which our &nbsp;
                <a
                  className="fmv-footer-text"
                  href="https://jnj.sharepoint.com/sites/jjhcc/Pages/PrivacyPolicy.aspx"
                >
                  <u>Privacy Policy</u>
                </a>
                &nbsp;does not apply. We encourage you to read the privacy
                policy of every website you visit. Your use of the information
                on this site is subject to the terms of our &nbsp;
                <a
                  className="fmv-footer-text"
                  href="https://jnj.sharepoint.com/sites/jjhcc/Pages/LegalNotice.aspx"
                >
                  <u>Legal Notice</u>
                </a>
                . © Copyright Johnson &amp; Johnson Services, Inc., 2008 &nbsp;
                <a
                  className="fmv-footer-text"
                  href="https://home.jnj.com/redir/1515937"
                >
                  <u>US States Policy</u>
                </a>
              </span>
            </Col>
            <Col md={2}></Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}