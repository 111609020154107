const calcConfigReducer = (state = {}, action) => {
  switch (action.type) {
    case "GET_CALC_DASHBOARD_DATA":
      return {
        ...state,
        calcDashboardData: action.payload,
        dataUpdated: false
      };
    case "GET_CALC_CONFIG_DATA":
      return {
        ...state,
        calcConfigData: action.payload
      };

    case "GET_SECTORS_MASTER_DATA":
      return {
        ...state,
        sectorsMasterData: action.payload
      };
    case "GET_REGIONS_MASTER_DATA":
      return {
        ...state,
        regionsMasterData: action.payload
      };
    case "GET_COUNTRY_MASTER_DATA":
      return {
        ...state,
        countryMasterData: action.payload
      };
    case "GET_LANGUAGE_MASTER_DATA":
      return {
        ...state,
        languageMasterData: action.payload
      };
    case "GET_CURRENCY_MASTER_DATA":
      return {
        ...state,
        currencyMasterData: action.payload
      };
    case "GET_PERCENTILE_MASTER_DATA":
      return {
        ...state,
        percentileMasterData: action.payload
      };
    case "GET_SEGMENTS_MASTER_DATA":
      return {
        ...state,
        segmentsMasterData: action.payload
      };
    case "GET_HCPSTATURE_MASTER_DATA":
      return {
        ...state,
        hcpStatureMasterData: action.payload
      };
    case "GET_ACTIVITY_TYPE_MASTER_DATA":
      return {
        ...state,
        activityTypeMasterData: action.payload
      };
    case "GET_TRAVEL_DISTANCE_MASTER_DATA":
      return {
        ...state,
        travelDistanceMasterData: action.payload
      };
    case "GET_HCP_SPECIALTY_MASTER_DATA":
      return {
        ...state,
        hcpSpecialtyMasterData: action.payload
      };
    case "GET_HCP_SPECIALTY_GROUP_MASTER_DATA":
      return {
        ...state,
        hcpSpecialtyGroupMasterData: action.payload
      };
    case "GET_REFERENCE_CATEGORY_MASTER_DATA":
      return {
        ...state,
        referenceCategoryMasterData: action.payload
      };

    case "SET_CALC_CONFIG_MAIN":
      return {
        ...state,
        calcConfigMain: action.payload
      };
    case "SET_CALC_CONFIG_BASIC_DATA":
      return {
        ...state,
        calcConfigBasic: action.payload
      };
    case "SET_CALC_CONFIG_HIDE_MULTIPLIER_DATA":
      return {
        ...state,
        hideMultiplierData: action.payload
      };
    case "SET_HCP_SEGMENT_DATA":
      return {
        ...state,
        hcpSegmentMul: action.payload
      };
    case "SET_HCP_STATURE_DATA":
      return {
        ...state,
        hcpStatureMul: action.payload
      };
    case "SET_COUNTRY_SPEC_LIMIT_DATA":
      return {
        ...state,
        countrySpecificLimits: action.payload
      };
    case "SET_ACTIVITY_TYPE_AND_CAPS_DATA":
      return {
        ...state,
        activityTypes: action.payload
      };
    case "SET_TRAVEL_TIME_DATA":
      return {
        ...state,
        travelTimes: action.payload
      };
    case "SET_GROUND_RULES_DATA":
      return {
        ...state,
        groundRules: action.payload
      };
    case "SET_HCP_SPECIALTY_DATA":
      return {
        ...state,
        hcpSpecialties: action.payload
      };

    case "CHECK_DUPLICATE_ITEM":
      return {
        ...state,
        duplicateCount: action.payload
      };

    case "SAVE_CALC_CONFIG_DATA":
      return {
        ...state,
        isSaving: action.payload
      };

    case "SAVE_AS_NEW_CALC_DATA":
      return {
        ...state,
        saveAsNew: action.payload
      };

    case "SET_SAVE_AS_NEW_DATA_PROCESSED":
      const saveAsNewProcessed = Object.assign(
        action.payload,
        state.saveAsNewProcessed
      );
      return {
        ...state,
        saveAsNewProcessed: saveAsNewProcessed
      };

    case "POST_CALC_CONFIG_DATA":
      return {
        ...state,
        updatedData: action.payload
      };

    case "GET_VARIABLES_MASTER_DATA":
      return {
        ...state,
        variablesData: action.payload
      };
    case "GET_MESSAGES_PAGES":
      return {
        ...state,
        messagePages: action.payload
      };
    case "GET_PAGE_CONFIG_MESSAGES_DATA":
      return {
        ...state,
        pageMessageData: action.payload
      };
    case "GET_PAGE_CONFIG_MESSAGES_DETAIL":
      return {
        ...state,
        configMessageDetail: action.payload,
        isMessageDetailLoaded: true
      };
    case "IS_EXECUTING_ACTION":
      return {
        ...state,
        isExecuting: action.payload
      };

    case "API_START":
      if (action.payload === "FETCH_MASTER_DATA") {
        return {
          ...state,
          isLoadingData: true
        };
      } else if (action.payload === "FETCH_CALC_CONFIG_DATA") {
        return {
          ...state,
          isLoadingConfiguration: true
        };
      } else if (action.payload === "CHECK_DUPLICATE") {
        return {
          ...state,
          isChecking: true
        };
      } else if (action.payload === "UPDATE_CALC_CONFIG_DATA") {
        return {
          ...state,
          isUpdatingData: true,
          dataUpdated: false
        };
      } else if (action.payload === "FETCH_CALC_DASHBOARD_DATA") {
        return {
          ...state
          // isLoadingData: true
        };
      } else if (
        action.payload === "FETCH_MESSAGES_PAGES" ||
        action.payload === "FETCH_PAGE_CONFIG_MESSAGES_DATA" ||
        action.payload === "FETCH_PAGE_CONFIG_MESSAGES_DETAIL"
      ) {
        return {
          ...state,
          isLoadingData: true
        };
      }
      break;
    case "API_END":
      if (action.payload === "FETCH_MASTER_DATA") {
        return {
          ...state,
          isLoadingData: false
        };
      } else if (action.payload === "FETCH_CALC_CONFIG_DATA") {
        return {
          ...state,
          isLoadingConfiguration: false
        };
      } else if (action.payload === "CHECK_DUPLICATE") {
        return {
          ...state,
          isChecking: false
        };
      } else if (action.payload === "UPDATE_CALC_CONFIG_DATA") {
        return {
          ...state,
          isUpdatingData: false,
          dataUpdated: true
        };
      } else if (action.payload === "FETCH_CALC_DASHBOARD_DATA") {
        return {
          ...state
          // isLoadingData: false
        };
      } else if (
        action.payload === "FETCH_MESSAGES_PAGES" ||
        action.payload === "FETCH_PAGE_CONFIG_MESSAGES_DATA" ||
        action.payload === "FETCH_PAGE_CONFIG_MESSAGES_DETAIL"
      ) {
        return {
          ...state,
          isLoadingData: false
        };
      }
      break;
    case "API_ERROR":
      return {
        ...state,
        isError: true,
        api_error: action.error
      };      
    default:
      return state;
  }
  return state;
};

export default calcConfigReducer;
