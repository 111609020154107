import apiAction from "./apiActionMethod";

export function fetchMasterConfigData(configListName) {
  return apiAction.getAPI({
    url:
      process.env.REACT_APP_API_BASE_URL +
      "masterData/" +
      configListName.toLowerCase() +
      "/ACTIVE/dashboard",
    onSuccess: getMasterConfigData,
    onFailure: err =>
      console.log("Error occured in fetching master config data.", err),
    label: "FETCH_MASTER_CONFIG_DATA"
  });
}

function getMasterConfigData(data) {
  return {
    type: "GET_MASTER_CONFIG_DATA",
    payload: data
  };
}

export function fetchRegionsMaster() {
  return apiAction.getAPI({
    url:
      process.env.REACT_APP_API_BASE_URL +
      "masterData/regions/ACTIVE/dashboard",
    onSuccess: getRegionsMaster,
    onFailure: err =>
      console.log("Error occured in fetching regions master config data.", err),
    label: "FETCH_MASTER_CONFIG_DATA"
  });
}

function getRegionsMaster(data) {
  return {
    type: "GET_REGIONS_MASTER",
    payload: data
  };
}

export function fetchHCPSpecialtyListMaster() {
  return apiAction.getAPI({
    url:
      process.env.REACT_APP_API_BASE_URL +
      "masterData/hcp_speciality_list/ACTIVE/dashboard",
    onSuccess: getHCPSpecialtyListMaster,
    onFailure: err =>
      console.log(
        "Error occured in fetching hcp_speciality_list master config data.",
        err
      ),
    label: "FETCH_MASTER_CONFIG_DATA"
  });
}

function getHCPSpecialtyListMaster(data) {
  return {
    type: "GET_HCPSPECIALTY_LIST_MASTER",
    payload: data
  };
}

export function checkDuplicate(configListName, dataToCheck) {
  return apiAction.updateAPI({
    url:
      process.env.REACT_APP_API_BASE_URL +
      "masterData/" +
      configListName.toLowerCase() +
      "/duplicateCheck",
    method: "POST",
    data: dataToCheck,
    onSuccess: checkDuplicateRecords,
    onFailure: err =>
      console.log(
        "Error occured in checking duplicate data for master config list : " +
          configListName,
        err
      ),
    label: "CHECK_DUPLICATE"
  });
}

function checkDuplicateRecords(data) {
  return {
    type: "CHECK_DUPLICATE_RECORDS",
    payload: data
  };
}

export function updateMasterConfigData(
  postMethod,
  configListName,
  dataToProcess,
  itemId
) {
  let apiURL =
    postMethod.toUpperCase() === "PATCH"
      ? process.env.REACT_APP_API_BASE_URL +
        "masterData/" +
        configListName.toLowerCase() +
        "/" +
        itemId
      : process.env.REACT_APP_API_BASE_URL +
        "masterData/" +
        configListName.toLowerCase();

  return apiAction.updateAPI({
    url: apiURL,
    method: postMethod,
    data: dataToProcess,
    onSuccess: postMasterConfigData,
    onFailure: err =>
      console.log("Error occured in updating master config data.", err),
    label: "UPDATE_MASTER_CONFIG_DATA"
  });
}

function postMasterConfigData(data) {
  return {
    type: "POST_MASTER_CONFIG_DATA",
    payload: data
  };
}

export function fetchUsersMaster() {
  return apiAction.getAPI({
    url:
      process.env.REACT_APP_API_BASE_URL + "masterData/users/ACTIVE/dashboard",
    onSuccess: getUsersMaster,
    onFailure: err =>
      console.log("Error occured in fetching users master config data.", err),
    label: "FETCH_MASTER_USERS_DATA"
  });
}

function getUsersMaster(data) {
  return {
    type: "GET_USERS_MASTER",
    payload: data
  };
}

export function fetchRolesMaster() {
  return apiAction.getAPI({
    url:
      process.env.REACT_APP_API_BASE_URL + "masterData/roles/ACTIVE/dashboard",
    onSuccess: getRolesMaster,
    onFailure: err =>
      console.log("Error occured in fetching roles master config data.", err),
    label: "FETCH_MASTER_ROLES_DATA"
  });
}

function getRolesMaster(data) {
  return {
    type: "GET_ROLES_MASTER",
    payload: data
  };
}

export function fetchAllUserList(configListName, searchUser = false) {
  return apiAction.getAPI({
    url:
      process.env.REACT_APP_API_BASE_URL +
      "ldaps/" +
      configListName.toLowerCase() +
      "/" +
      searchUser,
    onSuccess: getAllUserList,
    onFailure: err =>
      console.log("Error occured in fetching all user data.", err),
    label: "FETCH_ALL_USERLIST_DATA"
  });
}

function getAllUserList(data) {
  return {
    type: "GET_All_USERLIST_MASTER",
    payload: data
  };
}
