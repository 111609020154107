import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import _ from "lodash";
import Swal from "sweetalert2";
import "react-tabulator/lib/styles.css"; // default theme
import "react-tabulator/css/semantic-ui/tabulator_semantic-ui.min.css"; // use Theme(s)
import { Image } from "react-bootstrap";
import add from "../assets/add.svg";
import { ReactTabulator } from "react-tabulator";
import {
  fetchCalcDashboardData,
  fetchMasterData,
  fetchCalcConfigData,
  checkDuplicate,
  updateCalcConfigData
} from "../actions/calcConfig";
import { UserAccess } from "./userAccess";
import { ExcelRenderer } from "../helper/excelRenderer";
import { ShowLoader } from "../helper/showLoader";
import "moment";
const moment = require("moment");

export function CalculatorDashboard() {
  const [tableColumns, setTableColumns] = useState([]);
  const [calcConfigData, setCalcConfigData] = useState([]);
  const [isAccess, setIsAccess] = useState(false);
  // const [selectedFile, setSelectedFile] = useState([]);
  // const [isSelected, setIsSelected] = useState(false);
  const [calcName, setCalcName] = useState("");
  const [dataToSave, setDataToSave] = useState({});
  const [dataToLog, setDataToLog] = useState([]);

  const regionsMaster = useSelector(
    state => state.calcConfig.regionsMasterData
  );
  const countryMaster = useSelector(
    state => state.calcConfig.countryMasterData
  );
  const sectorsMaster = useSelector(
    state => state.calcConfig.sectorsMasterData
  );
  const currencyMaster = useSelector(
    state => state.calcConfig.currencyMasterData
  );
  const languageMaster = useSelector(
    state => state.calcConfig.languageMasterData
  );
  const percentileMaster = useSelector(
    state => state.calcConfig.percentileMasterData
  );
  const segmentsMaster = useSelector(
    state => state.calcConfig.segmentsMasterData
  );
  const statureMaster = useSelector(
    state => state.calcConfig.hcpStatureMasterData
  );
  const travelDistanceMaster = useSelector(
    state => state.calcConfig.travelDistanceMasterData
  );
  const activityTypeMaster = useSelector(
    state => state.calcConfig.activityTypeMasterData
  );
  const specialtyMaster = useSelector(
    state => state.calcConfig.hcpSpecialtyMasterData
  );
  const specialtyGroupMaster = useSelector(
    state => state.calcConfig.hcpSpecialtyGroupMasterData
  );
  const calcConfiguration = useSelector(
    state => state.calcConfig?.calcConfigData?.data
  );
  const duplicateResponse = useSelector(
    state => state.calcConfig.duplicateCount
  );
  const updatedData = useSelector(state => state.calcConfig.updatedData);

  const calcDashboardData = useSelector(
    state => state.calcConfig.calcDashboardData
  );
  const userInformation = useSelector(state => state.userInformation);

  const sectorOptions = useRef({});
  const redirectUrl = useRef("");
  const accessUrl = useRef("");
  const ref = useRef(null);
  const calcID = useRef("");
  const regionId = useRef("");
  const region_ID = useRef("");
  const countryID = useRef("");
  const country_region = useRef("");
  const sectorIDs = useRef("");
  const countryName = useRef("");
  const sectorName = useRef("");

  const dispatch = useDispatch();

  useEffect(() => {
    if (userInformation !== undefined && userInformation?.data !== undefined) {
      let systemadmin = false,
        businessadmin = false;
      _.map(userInformation?.data?.users[0]?.roles, o => {
        if (o?.role?.includes("System")) {
          systemadmin = true;
        }
      });
      _.map(userInformation?.data?.users[0]?.roles, o => {
        if (o?.role?.includes("Business")) {
          businessadmin = true;
        }
      });
      if (!systemadmin) {
        if (businessadmin) {
          if (userInformation?.data?.users[0]?.regionId !== undefined) {
            regionId.current = userInformation?.data?.users[0]?.regionId;
          }
        }
      }
      let pagesAccess = userInformation?.data?.users[0]?.accessPages;
      if (pagesAccess !== undefined) {
        _.map(pagesAccess, o => {
          if (o === "Calculator configuration") {
            setIsAccess(true);
          }
        });
        accessUrl.current = "/accessdenied";
      }
    }
  }, [userInformation]);

  useEffect(() => {
    dispatch(fetchCalcDashboardData());
    dispatch(fetchMasterData("sectors"));
    dispatch(fetchMasterData("regions"));
    dispatch(fetchMasterData("countries"));
    dispatch(fetchMasterData("currencies"));
    dispatch(fetchMasterData("languages"));
    dispatch(fetchMasterData("percentiles"));
    dispatch(fetchMasterData("segments"));
    dispatch(fetchMasterData("hcp_statures"));
    dispatch(fetchMasterData("travel_distances"));
    dispatch(fetchMasterData("activity_types"));
    dispatch(fetchMasterData("hcp_specialities"));
    dispatch(fetchMasterData("hcp_speciality_list"));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (sectorsMaster !== undefined) {
      sectorOptions.current = _.mapValues(
        _.keyBy(sectorsMaster?.data, "value"),
        "value"
      );
    }
    getTableColumns();
  }, [sectorsMaster]);

  useEffect(() => {
    let data = [];
    if (calcDashboardData !== undefined) {
      data = _.map(calcDashboardData?.data, function(obj) {
        return {
          country: obj.country,
          country_id: obj.country_id,
          created_at: obj.created_at,
          created_by: obj.created_by,
          deleted_by: obj.deleted_by,
          end_user_status: obj.end_user_status,
          latest_version: obj.latest_version,
          id: obj.id,
          last_save_status: obj.last_save_status,
          name: obj.name,
          region: obj.region,
          region_id: obj.region_id,
          sector_ids: obj.sector_ids,
          sectors: obj.sectors,
          updated_at: formatDate(obj.updated_at),
          updated_by: obj.updated_by,
          version: obj.version
        };
      });
      function formatDate(o) {
        let updateAt = new Date(o);
        return updateAt.getTime();
      }
    }
    if (calcDashboardData !== undefined && isAccess && data?.length > 0) {
      if (regionId.current !== "") {
        setCalcConfigData(
          _.filter(data, o => o.region_id === regionId.current)
        );
      } else if (regionId.current === "") {
        setCalcConfigData(data);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calcDashboardData, regionId.current, isAccess]);

  useEffect(() => {
    if (calcConfiguration && dataToSave && !_.isEmpty(dataToSave)) {
      if (calcID.current !== undefined && calcID.current !== "") {
        if (calcConfiguration?.calcConfig?.item_id) {
          dispatch(updateCalcConfigData("POST", dataToSave, calcID.current));
        } else {
          if (calcConfiguration !== undefined && calcConfiguration !== "") {
            let response = "";
            if (calcConfiguration.toLowerCase().includes("invalid uuid"))
              response =
                "Calculator ID is not valid! Please provide a valid Calculator ID in the import file.";
            else if (
              calcConfiguration
                .toLowerCase()
                .includes("no calculator for this id")
            )
              response =
                "No Calculator exists for this ID! Please provide a valid Calculator ID in the import file.";

            Swal.fire(
              "",
              "<strong style='color: red'>" + response + "</strong>",
              "warning"
            );
            return;
          }
        }
        setDataToSave({});
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calcConfiguration, dataToSave]);

  useEffect(() => {
    if (duplicateResponse && !_.isEmpty(dataToSave)) {
      if (duplicateResponse.count > 0 && duplicateResponse.sameCombination) {
        let message =
          "A " +
          duplicateResponse.save_status +
          " Calculator is already present for " +
          countryName.current +
          " - " +
          sectorName.current +
          ".";
        Swal.fire(
          "",
          "<strong style='color: red'>" + message + "</strong>",
          "warning"
        );
        return;
      } else {
        dispatch(updateCalcConfigData("POST", dataToSave));
      }
      setDataToSave({});
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duplicateResponse, dataToSave]);

  useEffect(() => {
    if (updatedData?.data && dataToLog) {
      if (updatedData.data?.calcConfig?.id) {
        let mssg = "",
          icon = "info";

        if (dataToLog.length > 0) {
          mssg =
            "<strong>Calculator configuration imported partially.</strong><div><strong>Please check the downloaded Error Log to verify the issues.</strong></div>";
          icon = "warning";
        } else {
          mssg =
            "<strong>Calculator configuration imported successfully without any error.</strong>";
          icon = "success";
        }
        Swal.fire({
          html: mssg,
          icon: icon,
          showCancelButton: false
        }).then(result => {
          if (result.isConfirmed) {
            exportErrorLog(calcName, dataToLog);
            window.location.href =
              "/calculatorconfig/" + updatedData.data?.calcConfig?.id;
          }
        });
      } else {
        calcID.current = "";
        region_ID.current = "";
        country_region.current = "";
        countryID.current = "";
        countryName.current = "";
        sectorIDs.current = "";
        sectorName.current = "";
        Swal.fire(
          "",
          "<strong>Error occurred while processing the imported file</strong>",
          "error"
        );
      }
      setDataToSave({});
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedData, dataToLog]);

  const onRowClick = function(e, row) {
    redirectUrl.current = "/calculatorconfig/" + row.getData().id;
    window.location.href = redirectUrl.current;
  };

  const getTableColumns = () => {
    let tableColumns = [
      {
        title: "Name",
        field: "name",
        headerFilter: "input",
        headerFilterPlaceholder: "Search by Name",
        tooltip: true,
        minWidth: 200,
        headerTooltip: true
      },
      {
        title: "Region",
        field: "region",
        headerFilter: "input",
        headerFilterPlaceholder: "Search",
        tooltip: true,
        width: 110,
        headerTooltip: true
      },
      {
        title: "Market",
        field: "country",
        headerFilter: "input",
        headerFilterPlaceholder: "Search",
        tooltip: true,
        width: 110,
        headerTooltip: true
      },
      {
        title: "Sector",
        field: "sectors",
        headerFilter: "select",
        headerFilterPlaceholder: "Filter",
        headerFilterParams: { values: sectorOptions.current },
        tooltip: true,
        width: 180,
        headerTooltip: true
      },
      {
        title: "Version",
        field: "version",
        headerFilter: "input",
        headerFilterPlaceholder: "Search",
        tooltip: true,
        width: 90,
        headerTooltip: true
      },
      {
        title: "Published Status",
        field: "end_user_status",
        headerFilter: "input",
        headerFilterPlaceholder: "Search",
        tooltip: true,
        width: 150,
        headerTooltip: true
      },
      {
        title: "Latest Status",
        field: "latest_version",
        headerFilter: "input",
        headerFilterPlaceholder: "Search",
        tooltip: true,
        width: 140,
        headerTooltip: true
      },
      {
        title: "Last Updated",
        field: "updated_at",
        formatter: function(cell, formatterParams, onRendered) {
          let dt = new Date(cell.getValue());
          return moment(dt).format("MMM DD, YYYY");
        },
        headerFilter: "input",
        headerFilterPlaceholder: "Search",
        headerFilterFunc: function(
          headerValue,
          rowValue,
          rowData,
          filterParams
        ) {
          let dt = new Date(rowValue);
          let dtStr = moment(dt).format("MMM DD, YYYY");
          return dtStr.toLowerCase().indexOf(headerValue.toLowerCase()) >= 0;
        },
        tooltip: true,
        width: 130,
        headerTooltip: true
      }
    ];
    setTableColumns(tableColumns);
  };

  const onCreateNewCalc = function() {
    redirectUrl.current = "/calculatorconfig";
    window.location.href = redirectUrl.current;
  };

  const importCalcConfiguration = event => {
    let fileupload = document.getElementById("uploadFileId");

    if (fileupload.value) {
      if (fileupload.value.match(/xlsx$/)) {
        // setSelectedFile(event.target.files[0]);
        // setIsSelected(true);

        let fileObj = event.target.files[0];

        ExcelRenderer(fileObj, (err, resp) => {
          if (err) {
            console.log(err);
          } else {
            let fileName = fileObj.name.substring(
              0,
              fileObj.name.indexOf(".xls")
            );
            setCalcName(fileName);
            processData(resp, fileName);
          }
        });
      } else {
        Swal.fire(
          "",
          "<strong>Please upload a xlsx file for import.</strong>",
          "warning"
        );
      }
    }
  };

  const processData = (resp, calcName) => {
    let mainObj = processMainData(resp);
    let travelDistData = processTravelDistData(resp);
    let activityTypeData = processActivityTypeData(resp);
    let hcpSpecialtyData = processHCPSpecialtyData(resp);

    let data = formatDataToSave(
      calcName,
      mainObj,
      travelDistData.travelDistObj,
      activityTypeData.activityTypeObj,
      hcpSpecialtyData.specialtyObj
    );
    setDataToSave(data.dataToSave);

    let errorLog = formatImportErrorLog(
      data.logData,
      data.isValidSpecialtyGroup,
      travelDistData.logObj,
      activityTypeData.logObj,
      hcpSpecialtyData.logObj
    );
    setDataToLog(errorLog);

    if (
      calcID.current !== undefined &&
      calcID.current !== null &&
      calcID.current !== ""
    ) {
      dispatch(fetchCalcConfigData(calcID.current));
    } else {
      if (
        region_ID.current &&
        region_ID.current !== "" &&
        countryID.current &&
        countryID.current !== "" &&
        sectorIDs.current &&
        sectorIDs.current.length > 0 &&
        country_region.current &&
        region_ID.current === country_region.current
      ) {
        let data = {
          country_id: countryID.current,
          sector_ids: sectorIDs.current,
          req_status: "DRAFT"
        };
        dispatch(checkDuplicate(data));
      } else {
        let message = "";

        if (sectorIDs.current === undefined || sectorIDs.current.length === 0)
          message =
            "Sector is not valid. Please provide valid Sector(s) for importing a calculator configuration.";
        if (countryID.current === undefined || countryID.current === "")
          message =
            "Market is not valid. Please provide a valid Market for importing a calculator configuration.";
        if (region_ID.current === undefined || region_ID.current === "")
          message =
            "Region is not valid. Please provide a valid Region for importing a calculator configuration.";
        if (
          region_ID.current !== undefined &&
          region_ID.current !== "" &&
          countryID.current !== undefined &&
          countryID.current !== "" &&
          region_ID.current !== country_region.current
        )
          message =
            "The Market provided in the import file does not fall under the same Region provided in the import file. Please check the Country and Region values.";

        Swal.fire(
          "",
          "<strong style='color: red'>" + message + "</strong>",
          "warning"
        );
        return;
      }
    }
  };

  const processMainData = resp => {
    let mainObj = {};

    if (resp.Main && resp.Main.rows) {
      let lastObj = {},
        section = "";
      let skipList = [
        "Field",
        "Country Identifier and Settings",
        "Band",
        "N/A",
        "Select specific HCP specialties",
        "HCP stature",
        "Select HCP stature",
        "Travel Time Discount (% of service per hour rate)"
      ];

      for (var i = 0; i < resp.Main.rows.length; i++) {
        var mainrow = resp.Main.rows[i];

        if (mainrow[0] !== undefined) {
          if (mainrow[0].toString().indexOf("---") >= 0) {
            if (section !== "") {
              mainObj[section] = lastObj;
              lastObj = {};
            }
            section = mainrow[0].substring(3);
          } else if (
            skipList.indexOf(mainrow[0].toString()) === -1 &&
            mainrow[0].toString() !== section
          ) {
            if (mainrow.length > 1) {
              lastObj[mainrow[0]] = mainrow[1] !== undefined ? mainrow[1] : "";
            } else {
              lastObj[mainrow[0]] = "";
            }
          }
        }
      }
      mainObj[section] = lastObj;
    }
    return mainObj;
  };

  const processTravelDistData = resp => {
    let skipCommon = ["Title"];
    let travelDistObj = [],
      logObj = [];

    if (resp.ActivityLocation && resp.ActivityLocation.rows) {
      for (var i = 0; i < resp.ActivityLocation.rows.length; i++) {
        var actlocation = resp.ActivityLocation.rows[i];

        if (
          actlocation[0] !== undefined &&
          actlocation[0] !== "" &&
          skipCommon.indexOf(actlocation[0].toString()) === -1
        ) {
          if (
            actlocation[1] !== undefined &&
            actlocation[1] !== "" &&
            actlocation.length > 2
          ) {
            let title = actlocation[1].toString();
            let item = _.find(travelDistanceMaster.data, o => {
              return (
                o.title.toLowerCase().trim() === title.toLowerCase().trim()
              );
            });

            if (item) {
              let travelDist = {
                travel_dist_id: item.id,
                time_hr: actlocation[2] !== undefined ? actlocation[2] : null,
                comments: actlocation[3] !== undefined ? actlocation[3] : ""
              };
              travelDistObj.push(travelDist);
            } else {
              let date = new Date();

              let log = {
                title: "Activity Location",
                activity_location: title,
                log: "Not able to process Activity Location: " + title,
                timestamp: date.toLocaleString()
              };
              logObj.push(log);
            }
          } else if (actlocation[1] === undefined || actlocation[1] === "") {
            let date = new Date();

            let log = {
              title: "Activity Location",
              activity_location: "",
              log: "Activity Location Name is blank or undefined",
              timestamp: date.toLocaleString()
            };
            logObj.push(log);
          }
        }
      }
    }
    return { travelDistObj: travelDistObj, logObj: logObj };
  };

  const processActivityTypeData = resp => {
    let skipCommon = ["Title"];
    let activityTypeObj = [],
      logObj = [];

    if (resp.ActivityType && resp.ActivityType.rows) {
      let nonKOLId = "";
      let nonKOL = _.find(statureMaster.data, o => {
        return o.value.toLowerCase().trim() === "non kol";
      });
      if (nonKOL) nonKOLId = nonKOL.id;

      let localKOLId = "";
      let localKOL = _.find(statureMaster.data, o => {
        return o.value.toLowerCase().trim() === "local kol";
      });
      if (localKOL) localKOLId = localKOL.id;

      let nationalKOLId = "";
      let nationalKOL = _.find(statureMaster.data, o => {
        return o.value.toLowerCase().trim() === "national kol";
      });
      if (nationalKOL) nationalKOLId = nationalKOL.id;

      let internationalKOLId = "";
      let internationalKOL = _.find(statureMaster.data, o => {
        return o.value.toLowerCase().trim() === "international kol";
      });
      if (internationalKOL) internationalKOLId = internationalKOL.id;

      for (var i = 0; i < resp.ActivityType.rows.length; i++) {
        var actType = resp.ActivityType.rows[i];

        if (
          actType[0] !== undefined &&
          actType[0] !== "" &&
          skipCommon.indexOf(actType[0].toString()) === -1
        ) {
          if (
            actType[1] !== undefined &&
            actType[1] !== "" &&
            actType.length > 3
          ) {
            let value = actType[1].toString();
            let item = _.find(activityTypeMaster.data, o => {
              return (
                o.title.toLowerCase().trim() === value.toLowerCase().trim()
              );
            });

            if (item) {
              let activityType = {
                activity_type_id: item.id,
                prep_time_hrs:
                  actType[2] !== undefined && actType[2] !== null
                    ? actType[2]
                    : "",
                service_time_hrs:
                  actType[3] !== undefined && actType[3] !== null
                    ? actType[3]
                    : "",
                comments:
                  actType[4] !== undefined && actType[4] !== null
                    ? actType[4]
                    : "",
                hr_cap:
                  actType[5] !== undefined && actType[5] !== null
                    ? actType[5]
                    : ""
              };
              let rateCap = [
                {
                  hcp_stature_id: nonKOLId,
                  rate_cap:
                    actType[6] !== undefined &&
                    actType[6] !== null &&
                    actType[6] !== ""
                      ? actType[6]
                      : ""
                },
                {
                  hcp_stature_id: localKOLId,
                  rate_cap:
                    actType[7] !== undefined &&
                    actType[7] !== null &&
                    actType[7] !== ""
                      ? actType[7]
                      : ""
                },
                {
                  hcp_stature_id: nationalKOLId,
                  rate_cap:
                    actType[8] !== undefined &&
                    actType[8] !== null &&
                    actType[8] !== ""
                      ? actType[8]
                      : ""
                },
                {
                  hcp_stature_id: internationalKOLId,
                  rate_cap:
                    actType[9] !== undefined &&
                    actType[9] !== null &&
                    actType[9] !== ""
                      ? actType[9]
                      : ""
                }
              ];
              let sessionCap = [
                {
                  hcp_stature_id: nonKOLId,
                  session_cap:
                    actType[10] !== undefined &&
                    actType[10] !== null &&
                    actType[10] !== ""
                      ? actType[10]
                      : ""
                },
                {
                  hcp_stature_id: localKOLId,
                  session_cap:
                    actType[11] !== undefined &&
                    actType[11] !== null &&
                    actType[11] !== ""
                      ? actType[11]
                      : ""
                },
                {
                  hcp_stature_id: nationalKOLId,
                  session_cap:
                    actType[12] !== undefined &&
                    actType[12] !== null &&
                    actType[12] !== ""
                      ? actType[12]
                      : ""
                },
                {
                  hcp_stature_id: internationalKOLId,
                  session_cap:
                    actType[13] !== undefined &&
                    actType[13] !== null &&
                    actType[13] !== ""
                      ? actType[13]
                      : ""
                }
              ];
              activityType.calcConfigActivityTypeHcpStatureCap = rateCap;
              activityType.calcConfigActivityTypeHcpStatureSessionCap = sessionCap;

              activityTypeObj.push(activityType);
            } else {
              let date = new Date();

              let log = {
                title: "Activity Type",
                activity_type: value,
                log: "Not able to process Activity Type: " + value,
                timestamp: date.toLocaleString()
              };
              logObj.push(log);
            }
          } else if (actType[1] === undefined || actType[1] === "") {
            let date = new Date();

            let log = {
              title: "Activity Type",
              activity_type: "",
              log: "Activity Type Name is blank or undefined",
              timestamp: date.toLocaleString()
            };
            logObj.push(log);
          }
        }
      }
    }
    return { activityTypeObj: activityTypeObj, logObj: logObj };
  };

  const processHCPSpecialtyData = resp => {
    let skipCommon = ["Title"];
    let specialtyObj = [],
      logObj = [];

    if (resp.SpecialtyList && resp.SpecialtyList.rows) {
      for (var i = 0; i < resp.SpecialtyList.rows.length; i++) {
        var specialtyRow = resp.SpecialtyList.rows[i];

        if (specialtyRow[0] !== undefined && specialtyRow[0] !== "") {
          if (
            skipCommon.indexOf(specialtyRow[0].toString()) === -1 &&
            specialtyRow[1] !== undefined &&
            specialtyRow[1] !== "" &&
            specialtyRow[2] !== undefined &&
            specialtyRow[2] !== "" &&
            specialtyRow.length > 2
          ) {
            let title = specialtyRow[1].toString();
            let item = _.find(specialtyMaster.data, o => {
              return (
                o.hcp_speciality.toLowerCase().trim() ===
                title.toLowerCase().trim()
              );
            });
            let segment = specialtyRow[2].toString();
            let segmentitem = _.find(segmentsMaster.data, o => {
              return (
                o.value.toLowerCase().trim() === segment.toLowerCase().trim()
              );
            });

            if (item && segmentitem) {
              let specialty = {
                hcp_speciality_id: item.id,
                segment_id: segmentitem.id,
                comments: specialtyRow[3] !== undefined ? specialtyRow[3] : ""
              };
              specialtyObj.push(specialty);
            } else {
              let date = new Date();

              let log = {
                title: "HCP Specialty",
                hcp_specialty: title,
                segment: segment,
                log:
                  item === undefined
                    ? "Not able to process HCP Specialty: " + title
                    : "Not able to process Segment: " + segment,
                timestamp: date.toLocaleString()
              };
              logObj.push(log);
            }
          } else if (specialtyRow[1] === undefined || specialtyRow[1] === "") {
            let date = new Date();

            let log = {
              title: "HCP Specialty",
              hcp_specialty: "",
              segment:
                specialtyRow[2] !== undefined ? specialtyRow[2].toString() : "",
              log: "HCP Specialty Name is blank or undefined",
              timestamp: date.toLocaleString()
            };
            logObj.push(log);
          }
        }
      }
    }
    return { specialtyObj: specialtyObj, logObj: logObj };
  };

  const formatDataToSave = (
    calcName,
    mainObj,
    travelDistObj,
    activityTypeObj,
    hcpSpecialtyObj
  ) => {
    let configMain = formatMainData(mainObj, calcName);
    let configBasic = formatBasicData(mainObj);
    let hcpSegments = formatHCPSegmentData(mainObj);
    let hcpStatures = formatHCPStatureData(mainObj);
    let countrySpecLimitData = formatCountrySpecData(mainObj);
    let travelDiscount = formatTravelTimeDiscount(mainObj);
    let rulesAndMessage = formatRulesAndMessage(mainObj);
    let specialtyGroup = formatSpecialtyGroup(mainObj);

    let dataToSave = {
      calcConfig: configMain.configMainObj,
      calcConfigBasic: configBasic.configBasicObj,
      ...(hcpSegments.segmentsObj
        ? { calcConfigHCPSegmentMul: hcpSegments.segmentsObj }
        : {}),
      ...(hcpStatures.staturesObj
        ? { calcConfigKolStatureMul: hcpStatures.staturesObj }
        : {}),
      ...(countrySpecLimitData
        ? { calcCountrySpecificLimit: countrySpecLimitData }
        : {}),
      ...(activityTypeObj
        ? { calcConfigActivityTypesHrsAndHrCap: activityTypeObj }
        : {}),
      ...(travelDistObj ? { calcConfigTravelTime: travelDistObj } : {}),
      ...(travelDiscount
        ? { calcConfigTravelTimeDiscount: travelDiscount }
        : {}),
      ...(rulesAndMessage.groundRules
        ? { calcConfigGroundRules: rulesAndMessage.groundRules }
        : {}),
      ...(rulesAndMessage.internalMessage
        ? { calcConfigMessages: rulesAndMessage.internalMessage }
        : {}),
      ...(specialtyGroup?.specialtyGroupId
        ? { hcpSpecialtyGroupId: specialtyGroup?.specialtyGroupId }
        : {}),
      ...(specialtyGroup?.specialtyGroupId && hcpSpecialtyObj
        ? { calcConfigHcpSpeciality: hcpSpecialtyObj }
        : {}),
      ...(configBasic?.configBasicObj?.hide_specialty_multiplier !== undefined
        ? {
            hide_speciality_multiplier:
              configBasic?.configBasicObj?.hide_specialty_multiplier
          }
        : {}),
      ...(configBasic?.configBasicObj?.hide_stature_multiplier !== undefined
        ? {
            hide_stature_multiplier:
              configBasic?.configBasicObj?.hide_stature_multiplier
          }
        : {})
    };

    let logData = [
      ...configMain.logObj,
      ...configBasic.logObj,
      ...hcpSegments.logObj,
      ...hcpStatures.logObj,
      ...specialtyGroup.logObj
    ];

    return {
      dataToSave: dataToSave,
      logData: logData,
      isValidSpecialtyGroup: specialtyGroup?.specialtyGroupId ? true : false
    };
  };

  const formatMainData = (obj, calcName) => {
    let logObj = [];

    let calculator_Id = obj["Main"]["Calculator ID"];
    calcID.current = calculator_Id;

    let regionId = "";
    if (obj.Main.Region && obj.Main.Region !== null && obj.Main.Region !== "") {
      let region = _.find(regionsMaster.data, o => {
        return (
          o.value.toLowerCase().trim() === obj.Main.Region.toLowerCase().trim()
        );
      });

      if (region) {
        regionId = region.id;
      } else {
        let date = new Date();
        let log = {
          field: "Region",
          value: obj.Main.Region,
          log: "Not able to process Region: " + obj.Main.Region,
          timestamp: date.toLocaleString()
        };
        logObj.push(log);
      }
      region_ID.current = regionId;
    }

    let countryId = "";
    if (
      obj.Main.Country &&
      obj.Main.Country !== null &&
      obj.Main.Country !== ""
    ) {
      countryName.current = obj.Main.Country;

      let country = _.find(countryMaster.data, o => {
        return (
          o.country.toLowerCase().trim() ===
          obj.Main.Country.toLowerCase().trim()
        );
      });
      if (country) {
        countryId = country.id;
        country_region.current = country.region_id;
      } else {
        let date = new Date();
        let log = {
          field: "Country",
          value: obj.Main.Country,
          log: "Not able to process Market: " + obj.Main.Country,
          timestamp: date.toLocaleString()
        };
        logObj.push(log);
      }
      countryID.current = countryId;
    }

    let sectorIds = [];
    if (obj.Main.Sector && obj.Main.Sector !== null && obj.Main.Sector !== "") {
      sectorName.current = obj.Main.Sector;

      let sectorNames = obj.Main.Sector.split(",");

      _.forEach(sectorNames, sectorname => {
        let sector = _.find(sectorsMaster.data, o => {
          return (
            o.value.toLowerCase().trim() === sectorname.toLowerCase().trim()
          );
        });
        if (sector) {
          sectorIds.push(sector.id);
        } else {
          let date = new Date();
          let log = {
            field: "Sector",
            value: sectorname,
            log: "Not able to process Sector: " + sectorname,
            timestamp: date.toLocaleString()
          };
          logObj.push(log);
        }
      });
    }
    sectorIDs.current = sectorIds;

    let configMainObj = {
      ...(calculator_Id !== undefined &&
      calculator_Id !== null &&
      calculator_Id !== ""
        ? { item_id: calculator_Id }
        : { name: calcName }),
      region_id: regionId,
      country_id: countryId,
      sector_ids: sectorIds
    };
    return { configMainObj: configMainObj, logObj: logObj };
  };

  const formatBasicData = obj => {
    let logObj = [];

    let currencyId = "";
    if (
      obj["Base Rate"]["Currency"] &&
      obj["Base Rate"]["Currency"] !== null &&
      obj["Base Rate"]["Currency"] !== ""
    ) {
      let currency = _.find(currencyMaster.data, o => {
        return (
          o.value.toLowerCase().trim() ===
          obj["Base Rate"]["Currency"].toLowerCase().trim()
        );
      });
      if (currency) {
        currencyId = currency.id;
      } else {
        let date = new Date();
        let log = {
          field: "Currency",
          value: obj["Base Rate"]["Currency"],
          log: "Not able to process Currency: " + obj["Base Rate"]["Currency"],
          timestamp: date.toLocaleString()
        };
        logObj.push(log);
      }
    }

    let baseRate = 0;
    if (
      obj["Base Rate"]["Base rate (in local currency)"] &&
      obj["Base Rate"]["Base rate (in local currency)"] !== null &&
      obj["Base Rate"]["Base rate (in local currency)"] !== ""
    ) {
      baseRate = parseFloat(obj["Base Rate"]["Base rate (in local currency)"]);
    }

    let rangesApplicable = [];
    if (
      obj["Range Inputs"]["Ranges Applicable"] &&
      obj["Range Inputs"]["Ranges Applicable"] !== null &&
      obj["Range Inputs"]["Ranges Applicable"] !== ""
    ) {
      rangesApplicable = obj["Range Inputs"]["Ranges Applicable"]
        .toLowerCase()
        .split(",");
    }

    let minRange = 0;
    if (rangesApplicable.indexOf("min") >= 0) {
      if (
        obj["Range Inputs"]["Minimum %"] &&
        obj["Range Inputs"]["Minimum %"] !== null &&
        obj["Range Inputs"]["Minimum %"] !== ""
      ) {
        minRange = parseFloat(obj["Range Inputs"]["Minimum %"]);
      }
    } else {
      minRange = "";
    }
    let maxRange = 0;
    if (rangesApplicable.indexOf("max") >= 0) {
      if (
        obj["Range Inputs"]["Maximum %"] &&
        obj["Range Inputs"]["Maximum %"] !== null &&
        obj["Range Inputs"]["Maximum %"] !== ""
      ) {
        maxRange = parseFloat(obj["Range Inputs"]["Maximum %"]);
      }
    } else {
      maxRange = "";
    }

    let roundOf = 0;
    if (
      obj["Rounding"]["Round to multiple of"] &&
      obj["Rounding"]["Round to multiple of"] !== null &&
      obj["Rounding"]["Round to multiple of"] !== ""
    ) {
      roundOf = parseInt(obj["Rounding"]["Round to multiple of"]);
    }
    let isKolApplicable = false;
    if (
      obj["Country Identifier and Settings"][
        "Is 'KOL Assessment' applicable?"
      ] &&
      obj["Country Identifier and Settings"][
        "Is 'KOL Assessment' applicable?"
      ] !== null
    ) {
      isKolApplicable =
        obj["Country Identifier and Settings"][
          "Is 'KOL Assessment' applicable?"
        ].toLowerCase() === "yes"
          ? true
          : false;
    }
    let isTranslationApplicable = false;
    if (
      obj["Country Identifier and Settings"]["Is 'Translation' applicable?"] &&
      obj["Country Identifier and Settings"]["Is 'Translation' applicable?"] !==
        null
    ) {
      isTranslationApplicable =
        obj["Country Identifier and Settings"]["Is 'Translation' applicable?"]
          .toLowerCase()
          .trim() === "yes"
          ? true
          : false;
    }

    let transLanguages = [];
    if (
      obj["Country Identifier and Settings"]["Translation Languagues"] &&
      obj["Country Identifier and Settings"]["Translation Languagues"] !==
        null &&
      obj["Country Identifier and Settings"]["Translation Languagues"] !== ""
    ) {
      let languages = obj["Country Identifier and Settings"][
        "Translation Languagues"
      ].split(",");

      _.each(languages, lan => {
        let item = _.find(languageMaster.data, o => {
          return o.value.toLowerCase().trim() === lan.toLowerCase().trim();
        });
        if (item) {
          var languageObj = {
            id: item.id,
            name: lan
          };
          transLanguages.push(languageObj);
        } else {
          let date = new Date();
          let log = {
            field: "Translation Languague",
            value: lan,
            log: "Not able to process Translation Languague: " + lan,
            timestamp: date.toLocaleString()
          };
          logObj.push(log);
        }
      });
    }

    let languageId = "";
    if (
      obj["Country Identifier and Settings"]["Default Language"] &&
      obj["Country Identifier and Settings"]["Default Language"] !== null &&
      obj["Country Identifier and Settings"]["Default Language"] !== ""
    ) {
      let language = _.find(languageMaster.data, o => {
        return (
          o.value.toLowerCase().trim() ===
          obj["Country Identifier and Settings"]["Default Language"]
            .toLowerCase()
            .trim()
        );
      });
      if (language) {
        languageId = language.id;
      } else {
        let date = new Date();
        let log = {
          field: "Default Language",
          value: obj["Country Identifier and Settings"]["Default Language"],
          log:
            "Not able to process Default Language: " +
            obj["Country Identifier and Settings"]["Default Language"],
          timestamp: date.toLocaleString()
        };
        logObj.push(log);
      }
    }

    let decimalPointer = "Point(.)";
    if (
      obj["Country Identifier and Settings"]["Decimal Separator"] &&
      obj["Country Identifier and Settings"]["Decimal Separator"] !== null &&
      obj["Country Identifier and Settings"]["Decimal Separator"] !== ""
    ) {
      decimalPointer =
        obj["Country Identifier and Settings"]["Decimal Separator"];
    }
    let thousandPointer = "Comma(,)";
    if (
      obj["Country Identifier and Settings"]["Thousand Separator"] &&
      obj["Country Identifier and Settings"]["Thousand Separator"] !== null &&
      obj["Country Identifier and Settings"]["Thousand Separator"] !== ""
    ) {
      thousandPointer =
        obj["Country Identifier and Settings"]["Thousand Separator"];
    }

    let percentileId = "";
    if (
      obj["Country Identifier and Settings"]["Percentile"] &&
      obj["Country Identifier and Settings"]["Percentile"] !== null &&
      obj["Country Identifier and Settings"]["Percentile"] !== ""
    ) {
      let percentile = _.find(percentileMaster.data, o => {
        return (
          o.value.toLowerCase().trim() ===
          obj["Country Identifier and Settings"]["Percentile"]
            .toLowerCase()
            .trim()
        );
      });
      if (percentile) {
        percentileId = percentile.id;
      } else {
        let date = new Date();
        let log = {
          field: "Percentile",
          value: obj["Country Identifier and Settings"]["Percentile"],
          log:
            "Not able to process Percentile: " +
            obj["Country Identifier and Settings"]["Percentile"],
          timestamp: date.toLocaleString()
        };
        logObj.push(log);
      }
    }

    let hideSpecialtyMultiplier = false;
    if (
      obj["Country Identifier and Settings"]["Hide Specialty Multiplier"] &&
      obj["Country Identifier and Settings"]["Hide Specialty Multiplier"] !==
        null
    ) {
      hideSpecialtyMultiplier =
        obj["Country Identifier and Settings"]["Hide Specialty Multiplier"]
          .toLowerCase()
          .trim() === "yes"
          ? true
          : false;
    }

    let hideStatureMultiplier = false;
    if (
      obj["Country Identifier and Settings"]["Hide Stature Multiplier"] &&
      obj["Country Identifier and Settings"]["Hide Stature Multiplier"] !== null
    ) {
      hideStatureMultiplier =
        obj["Country Identifier and Settings"]["Hide Stature Multiplier"]
          .toLowerCase()
          .trim() === "yes"
          ? true
          : false;
    }

    let truevaultLink = "";
    if (
      obj["Country Identifier and Settings"]["TrueVault Link"] &&
      obj["Country Identifier and Settings"]["TrueVault Link"] !== null &&
      obj["Country Identifier and Settings"]["TrueVault Link"] !== ""
    ) {
      truevaultLink = obj["Country Identifier and Settings"]["TrueVault Link"];
    }
    let comments = "";
    if (
      obj["Country Identifier and Settings"]["Comments"] &&
      obj["Country Identifier and Settings"]["Comments"] !== null &&
      obj["Country Identifier and Settings"]["Comments"] !== ""
    ) {
      comments = obj["Country Identifier and Settings"]["Comments"];
    }

    let configBasicObj = {
      ...(currencyId && currencyId !== "" ? { currency_id: currencyId } : {}),
      base_rate: baseRate,
      ranges_applicable: rangesApplicable,
      min_range_percentage: minRange,
      max_range_percentage: maxRange,
      round_of: roundOf,
      is_kol_assessment_applicable: isKolApplicable,
      is_translation_available: isTranslationApplicable,
      ...(languageId && languageId !== "" ? { lang_id: languageId } : {}),
      available_translated_languages: JSON.stringify(transLanguages),
      punc_decimal_pointer: decimalPointer,
      punc_thousand_pointer: thousandPointer,
      percentile: percentileId,
      hide_specialty_multiplier: hideSpecialtyMultiplier,
      hide_stature_multiplier: hideStatureMultiplier,
      true_vault_link: truevaultLink,
      comments: comments,
      isDraft: true
    };
    return { configBasicObj: configBasicObj, logObj: logObj };
  };

  const formatHCPSegmentData = obj => {
    var segmentsObj = [],
      logObj = [];

    if (
      obj["HCP Segments and Multipliers"] &&
      obj["HCP Segments and Multipliers"] !== null
    ) {
      _.each(obj["HCP Segments and Multipliers"], (val, key) => {
        let item = _.find(segmentsMaster.data, o => {
          return o.value.toLowerCase().trim() === key.toLowerCase().trim();
        });
        if (item) {
          var segment = {
            segment_id: item.id,
            multiplier: val
          };
          segmentsObj.push(segment);
        } else {
          let date = new Date();
          let log = {
            field: "HCP Segments and Multipliers",
            value: key,
            log: "Not able to process HCP Segment: " + key,
            timestamp: date.toLocaleString()
          };
          logObj.push(log);
        }
      });
    }
    return { segmentsObj: segmentsObj, logObj: logObj };
  };

  const formatHCPStatureData = obj => {
    var staturesObj = [],
      logObj = [];

    if (
      obj["HCP KOL Stature and Multipliers"] &&
      obj["HCP KOL Stature and Multipliers"] !== null
    ) {
      _.each(obj["HCP KOL Stature and Multipliers"], (val, key) => {
        let item = _.find(statureMaster.data, o => {
          return o.value.toLowerCase().trim() === key.toLowerCase().trim();
        });
        if (item) {
          var stature = {
            hcp_stature_id: item.id,
            multiplier: val
          };
          staturesObj.push(stature);
        } else {
          let date = new Date();
          let log = {
            field: "HCP KOL Stature and Multipliers",
            value: key,
            log: "Not able to process HCP KOL Stature: " + key,
            timestamp: date.toLocaleString()
          };
          logObj.push(log);
        }
      });
    }
    return { staturesObj: staturesObj, logObj: logObj };
  };

  const formatCountrySpecData = obj => {
    let rateHrLimit = "";
    if (
      obj["Country Specific Limits"]["Rate/Hourly Limit - General"] &&
      obj["Country Specific Limits"]["Rate/Hourly Limit - General"] !== null
    ) {
      rateHrLimit =
        obj["Country Specific Limits"]["Rate/Hourly Limit - General"];
    }
    let hrLimitGen = "";
    if (
      obj["Country Specific Limits"]["Hourly Limit - General"] &&
      obj["Country Specific Limits"]["Hourly Limit - General"] !== null
    ) {
      hrLimitGen = obj["Country Specific Limits"]["Hourly Limit - General"];
    }
    let sessionLimitGen = "";
    if (
      obj["Country Specific Limits"]["Session Limit - General"] &&
      obj["Country Specific Limits"]["Session Limit - General"] !== null
    ) {
      sessionLimitGen =
        obj["Country Specific Limits"]["Session Limit - General"];
    }

    let countrySpecLimitData = {
      rate_per_hr_limit: rateHrLimit,
      hour_limit: hrLimitGen,
      session_limit_general: sessionLimitGen
    };
    return countrySpecLimitData;
  };

  const formatTravelTimeDiscount = obj => {
    let travelDiscount = {
      ...(obj["Travel Time Discount"]["% of the hourly rate"] &&
      obj["Travel Time Discount"]["% of the hourly rate"] !== null &&
      obj["Travel Time Discount"]["% of the hourly rate"] !== ""
        ? {
            percentage_discount: parseFloat(
              obj["Travel Time Discount"]["% of the hourly rate"]
            )
          }
        : {})
    };
    return travelDiscount;
  };

  const formatRulesAndMessage = obj => {
    let rulesAndMessage = {};

    let groundRules = [];
    if (obj["Company Ground Rules"] && obj["Company Ground Rules"] !== null) {
      _.each(obj["Company Ground Rules"], (val, key) => {
        var rule = {
          rule_desc: key,
          order: val
        };
        groundRules.push(rule);
      });
    }

    let internalMessage = "";
    if (
      obj["Internal Message"]["Message"] &&
      obj["Internal Message"]["Message"] !== null
    ) {
      internalMessage = obj["Internal Message"]["Message"];
    }

    rulesAndMessage = {
      groundRules: groundRules,
      internalMessage: [
        {
          message_type: "Internal Message",
          messages: internalMessage
        }
      ]
    };
    return rulesAndMessage;
  };

  const formatSpecialtyGroup = obj => {
    let specialtyGroup = "";
    let logObj = [];

    let groupname = obj["HCP Specialty Group"]["Specialty Group"];

    if (groupname && groupname !== null && groupname !== "") {
      let item = _.find(specialtyGroupMaster.data, o => {
        return o.value.toLowerCase().trim() === groupname.toLowerCase().trim();
      });

      if (item) {
        specialtyGroup = item.id;
      } else {
        let date = new Date();
        let log = {
          field: "Specialty Group",
          value: groupname,
          log: "Not able to process Specialty Group: " + groupname,
          timestamp: date.toLocaleString()
        };
        logObj.push(log);
      }
    }
    return {
      ...(specialtyGroup && specialtyGroup !== ""
        ? { specialtyGroupId: specialtyGroup }
        : {}),
      logObj: logObj
    };
  };

  const formatImportErrorLog = (
    mainLog,
    isValidSpecialtyGroup,
    travelDistLog,
    activityTypeLog,
    specialtyLog
  ) => {
    let data = [];

    if (
      mainLog.length === 0 &&
      travelDistLog.length === 0 &&
      activityTypeLog.length === 0 &&
      specialtyLog.length === 0
    ) {
      data = [];
    } else {
      if (!isValidSpecialtyGroup) {
        let date = new Date();

        specialtyLog = [
          {
            title: "HCP Specialty",
            hcp_specialty:
              "No HCP Specialty is processed as Specialty Group is invalid or undefined",
            segment: "",
            log:
              "No HCP Specialty is processed as Specialty Group is invalid or undefined",
            timestamp: date.toLocaleString()
          }
        ];
      }
      data = [
        {
          sheet: "Main",
          columns: [
            { label: "Field", value: "field" },
            { label: "Value", value: "value" },
            { label: "Log", value: "log" },
            { label: "Timestamp", value: "timestamp" }
          ],
          content: mainLog
        },
        {
          sheet: "ActivityLocation",
          columns: [
            { label: "Title", value: "title" },
            { label: "Activity Location", value: "activity_location" },
            { label: "Log", value: "log" },
            { label: "Timestamp", value: "timestamp" }
          ],
          content: travelDistLog
        },
        {
          sheet: "ActivityType",
          columns: [
            { label: "Title", value: "title" },
            { label: "Activity Type", value: "activity_type" },
            { label: "Log", value: "log" },
            { label: "Timestamp", value: "timestamp" }
          ],
          content: activityTypeLog
        },
        {
          sheet: "SpecialtyList",
          columns: [
            { label: "Title", value: "title" },
            { label: "HCP Specialty", value: "hcp_specialty" },
            { label: "Segment", value: "segment" },
            { label: "Log", value: "log" },
            { label: "Timestamp", value: "timestamp" }
          ],
          content: specialtyLog
        }
      ];
    }
    return data;
  };

  const exportErrorLog = (calcName, dataToExport) => {
    let date = new Date();
    let fileName =
      "Import_ErrorLog_" +
      calcName.toString() +
      "_" +
      date
        .toDateString()
        .substring(4)
        .replaceAll(" ", "-");

    // export to excel
    let settings = {
      fileName: fileName,
      extraLength: 3, // A bigger number means that columns will be wider
      writeOptions: {} // Style options from https://github.com/SheetJS/sheetjs#writing-options
    };
    let xlsx = require("json-as-xlsx");
    xlsx(dataToExport, settings);
  };

  return (
    <>
      {accessUrl.current !== "" &&
        (!isAccess ? <Redirect to={accessUrl.current} /> : "")}
      {isAccess ? (
        <div className="masterdata-config">
          <ShowLoader></ShowLoader>

          <div className="table-heading">
            <span>Calculator Dashboard</span> &nbsp;&nbsp;
            <div>
              <UserAccess UserRolesPermission="calculatorconfig">
                <Image
                  src={add}
                  className="add-item"
                  title="Create New Calculator"
                  alt="Create New Calculator"
                  onClick={() => onCreateNewCalc()}
                ></Image>
              </UserAccess>
            </div>
            <UserAccess UserRolesPermission="calculatorconfig">
              <div className="import-calculator">
                <input
                  type="file"
                  name="file"
                  id="uploadFileId"
                  className="import-excel"
                  onChange={importCalcConfiguration}
                  onClick={event => {
                    event.target.value = null;
                  }}
                />
              </div>
            </UserAccess>
          </div>
          <div className="tabulator-wrapper">
            {calcConfigData !== undefined ? (
              <ReactTabulator
                ref={a => (ref.current = a)}
                columns={tableColumns}
                data={calcConfigData}
                key={"CalculatorDashboard"}
                rowClick={onRowClick}
                height="100%"
                layout="fitColumns"
              />
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
