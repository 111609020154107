import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap";
import _ from "lodash";
import Select from "react-select";
import { RenderRichText } from "../helper/renderRichText";
import { ShowLoader } from "../helper/showLoader";
import { FMVCalculator } from "./fmvCalculator";
import { FMVRateCard } from "./fmvRateCard";
import { FMVKOLAssessment } from "./fmvKOLAssessment";
import { FMVKolDefinition } from "./fmvKolDefinition";
import {
  fetchPagesMessages,
  fetchPageConfigMessagesData
} from "../actions/calcConfig";
import {
  fetchCountrySectorOptions,
  fetchCalculatorData,
  setSelectedTransLanguage,
  setSelectedTranslationData,
  setCountrySectorNameData,
  previewCalculator,
  setDraftVersionStatus,
  setCalculatorHeaderData,
  resetCalculatorData,
  setIsPreviewCalculatorData
} from "../actions/calculator";
import Swal from "sweetalert2";

export function Calculator() {
  const monthsList = useRef([
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ]);

  const [initializer, setInitializer] = useState(false);
  const [isView, setIsView] = useState(false);
  // const [isAdmin, setIsAdmin] = useState(false);
  const [countriesListObj, setCountriesListObj] = useState([]);
  const [sectorsListObj, setSectorsListObj] = useState([]);
  const [translationsListObj, setTranslationsListObj] = useState([]);
  const [translationsData, setTranslationsData] = useState([]);
  const [countryId, setCountryId] = useState("");
  const [regionListObj, setRegionListObj] = useState([]);
  const [regionId, setRegionId] = useState("");
  const [country, setCountry] = useState("");
  const [showSectorOption, setShowSectorOption] = useState(false);
  const [sectorId, setSectorId] = useState("");
  const [regionObj, setRegionObj] = useState("");
  const [sectorObj, setSectorObj] = useState("");
  const [countryObj, setCountryObj] = useState("");
  const [transLanguageId, setTransLanguageId] = useState("");
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [showGenerateLink, setShowGenerateLink] = useState(true);
  const [pageId, setPageId] = useState("");
  const [disclaimer, setDisclaimer] = useState("");
  const [globalCapMssg, setGlobalCapMssg] = useState("");
  const [isNoCalculator, setIsNoCalculator] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const [noCalculatorMessage, setNoCalculatorMessage] = useState("");
  const [calcName, setCalcName] = useState("");
  // const [userName, setUserName] = useState("");
  // const [userEmail, setUserEmail] = useState("");
  const [calcRegion, setCalcRegion] = useState("");
  const [calcSectors, setCalcSectors] = useState("");
  const [calcVersion, setCalcVersion] = useState("");
  const [lastPublished, setLastPublished] = useState("");
  const [internalMessage, setInternalMessage] = useState("");
  const [groundRules, setGroundRules] = useState([]);
  const [isKOLApplicable, setIsKOLApplicable] = useState(false);
  const [isTranslationAvailable, setIsTranslationAvailable] = useState(false);
  const [disclaimerAcknowledged, setDisclaimerAcknowledged] = useState(false);

  const [sectorLabel, setSectorLabel] = useState("Sector");
  const [regionLabel, setRegionLabel] = useState("Region");
  const [countryLabel, setCountryLabel] = useState("Market");
  const [internalMessageLabel, setInternalMessageLabel] = useState(
    "Internal Message"
  );
  const [groundRulesLabel, setGroundRulesLabel] = useState("Ground Rules");
  const [lastPublishedLabel, setLastPublishedLabel] = useState(
    "Last Published On"
  );
  const [lastUpdatedLabel, setLastUpdatedLabel] = useState("Last Updated On");
  const [FMVCalculatorLabel, setFMVCalculatorLabel] = useState(
    "FMV Calculator"
  );
  const [FMVHourlyRateCardLabel, setFMVHourlyRateCardLabel] = useState(
    "FMV Hourly Rate Card"
  );
  // const [KOLAssessmentLabel, setKOLAssessmentLabel] = useState(
  //   "KOL Assessment"
  // );
  // const [KOLDefinitionLabel, setKOLDefinitionLabel] = useState(
  //   "KOL Definition"
  // );

  const rateCardData = useRef([]);
  const isPreview = useRef(false);

  // const calcConfigData = useSelector((state) => state.calcConfig?.calcConfigData?.data);
  const countrySectorOptions = useSelector(
    state => state.calculator?.countrySectorOptions
  );
  const pageConfig = useSelector(state => state.calcConfig?.messagePages);
  const pageMessages = useSelector(state => state.calcConfig?.pageMessageData);
  const calculatorData = useSelector(state => state.calculator?.calculatorData);
  const userInformation = useSelector(state => state.userInformation);

  let { param1 } = useParams();
  let { param2 } = useParams();
  let { preview } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPagesMessages());
    dispatch(fetchCountrySectorOptions());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userInformation && userInformation?.data) {
      // setUserName(
      //   (
      //     userInformation.data.users[0].givenName +
      //     " " +
      //     userInformation.data.users[0].sn
      //   ).toUpperCase()
      // );
      // setUserEmail(userInformation.data.users[0].mail);

      let adminRole = _.find(userInformation?.data?.users[0]?.roles, o => {
        return o?.role?.toLowerCase()?.includes("admin");
      });
      if (adminRole && adminRole.roleId) {
        // setIsAdmin(true);
      }
    }
  }, [userInformation]);

  useEffect(() => {
    if (!initializer) {
      if (preview && param1 && param2 && countrySectorOptions) {
        if (preview.toLowerCase() !== "preview") {
          setIsView(false);
          setCountryId(param1);
          setSectorId(param2);
          setShowSectorOption(true);
          setShowDisclaimer(true);
          setInitializer(true);
          isPreview.current = false;
        } else {
          let calcId = param1;
          let version = param2 ? param2 : "";
          let data = {
            calc_id: calcId,
            ...(version !== "" && version ? { version: version } : {})
          };
          setIsView(true);
          setShowSectorOption(true);
          setShowDisclaimer(true);
          setInitializer(true);
          setShowGenerateLink(false);
          dispatch(previewCalculator(data));
        }
      } else if (preview && param1 && countrySectorOptions) {
        if (preview.toLowerCase() === "preview" && param1 !== "") {
          let calcId = param1;
          let version = param2 ? param2 : "";
          let data = {
            calc_id: calcId,
            ...(version !== "" && version ? { version: version } : {})
          };
          setIsView(true);
          setShowSectorOption(true);
          setShowDisclaimer(true);
          setInitializer(true);
          setShowGenerateLink(false);
          dispatch(previewCalculator(data));
        }
      }
    } else {
      if (preview) {
        if (preview.toLowerCase() === "preview") {
          isPreview.current = true;
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initializer, preview, param1, param2, countrySectorOptions]);

  useEffect(() => {
    if (isPreview.current !== "") {
      dispatch(setIsPreviewCalculatorData(isPreview.current));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPreview.current]);

  useEffect(() => {
    if (
      preview &&
      countryId &&
      sectorId &&
      countriesListObj &&
      sectorsListObj
    ) {
      if (preview.toLowerCase() === "preview") {
        setRegionObj(
          _.find(regionListObj, o => {
            return o.name === regionId;
          })
        );
      }
      setCountryObj(
        _.find(countriesListObj, o => {
          return o.name === countryId;
        })
      );
      setSectorObj(
        _.find(sectorsListObj, o => {
          return o.name === sectorId;
        })
      );
    }
  }, [
    preview,
    regionId,
    countryId,
    sectorId,
    regionListObj,
    countriesListObj,
    sectorsListObj
  ]);

  useEffect(() => {
    if (countrySectorOptions) {
      let regionSearchList = _.sortBy(
        countrySectorOptions?.data?.regions,
        "region"
      ).map(obj => {
        return { name: obj.region_id, label: obj.region };
      });
      setRegionListObj(regionSearchList);
    }
  }, [countrySectorOptions]);

  useEffect(() => {
    if (countrySectorOptions) {
      let allCountriesData = [];
      if (regionId && regionId !== null && regionId !== "") {
        _.map(countrySectorOptions?.data?.regions, obj => {
          if (obj.region_id === regionId) {
            allCountriesData = obj?.countries;
          }
        });
      } else {
        _.forEach(countrySectorOptions?.data?.regions, function(obj) {
          _.map(obj.countries, o => {
            allCountriesData.push(o);
          });
        });
        allCountriesData = _.uniqBy(allCountriesData, "country_id");
      }

      if (allCountriesData) {
        let countrySearchList = _.sortBy(allCountriesData, "country").map(
          obj => {
            return { name: obj.country_id, label: obj.country };
          }
        );
        setCountriesListObj(countrySearchList);
      }
    }
  }, [countrySectorOptions, regionId]);

  useEffect(() => {
    if (countryId && countryId !== null && countryId !== "") {
      let allCountriesData = [];
      let selectedCountry = [];
      if (regionId && regionId !== null && regionId !== "") {
        _.map(countrySectorOptions?.data?.regions, obj => {
          if (obj.region_id === regionId) {
            allCountriesData = obj?.countries;
          }
        });
        selectedCountry = _.find(allCountriesData, o => {
          return o.country_id === countryId;
        });
      } else {
        _.forEach(countrySectorOptions?.data?.regions, function(obj) {
          _.map(obj.countries, o => {
            allCountriesData.push(o);
          });
        });
        allCountriesData = _.uniqBy(allCountriesData, "country_id");
        selectedCountry = _.find(allCountriesData, o => {
          return o.country_id === countryId;
        });
      }
      if (selectedCountry) {
        setCountry(selectedCountry?.country);

        let SectorSearchList = _.sortBy(selectedCountry?.sectors, "sector").map(
          obj => {
            return { name: obj.sector_id, label: obj.sector };
          }
        );
        setSectorsListObj(SectorSearchList);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryId]);

  useEffect(() => {
    if (pageConfig !== undefined) {
      let data = _.find(pageConfig?.data, o => {
        return o.page_name.toUpperCase() === "CALCULATOR CONFIGURATION";
      });
      if (data !== undefined) {
        setPageId(data.id);
      }
    }
  }, [pageConfig]);

  useEffect(() => {
    if (pageId !== undefined && pageId !== "") {
      dispatch(fetchPageConfigMessagesData(pageId));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageId]);

  useEffect(() => {
    if (pageMessages !== undefined) {
      setDisclaimer(
        _.find(pageMessages?.data, o => {
          return o.content_type.toUpperCase() === "DISCLAIMER";
        }).contents
      );
      setGlobalCapMssg(
        _.find(pageMessages?.data, o => {
          return o.content_type.toUpperCase() === "GLOBAL CAP MESSAGE";
        }).contents
      );
    }
  }, [pageMessages]);

  useEffect(() => {
    if (
      transLanguageId !== undefined &&
      transLanguageId !== "" &&
      translationsData !== undefined
    ) {
      let selectedLanguage = _.find(translationsData, o => {
        return o?.id === transLanguageId;
      })?.name;
      if (
        selectedLanguage &&
        selectedLanguage !== null &&
        selectedLanguage !== ""
      ) {
        dispatch(setSelectedTransLanguage(selectedLanguage));
      }

      let selectedTranslation = _.find(translationsData, o => {
        return o?.id === transLanguageId;
      })?.translations;
      let jsonTranslation = JSON.parse(selectedTranslation);

      if (jsonTranslation !== undefined && jsonTranslation !== "") {
        dispatch(setSelectedTranslationData(jsonTranslation));
        function findTranslation(keyname) {
          if (
            _.find(jsonTranslation, o => {
              return o?.KEY === keyname;
            })?.VALUE &&
            _.find(jsonTranslation, o => {
              return o?.KEY === keyname;
            })?.VALUE !== "" &&
            _.find(jsonTranslation, o => {
              return o?.KEY === keyname;
            })?.VALUE !== undefined
          ) {
            return _.find(jsonTranslation, o => {
              return o?.KEY === keyname;
            })?.VALUE;
          } else {
            return "";
          }
        }
        setSectorLabel(
          findTranslation("sector") !== ""
            ? findTranslation("sector")
            : "Sector"
        );
        setCountryLabel(
          findTranslation("Market") !== ""
            ? findTranslation("Market")
            : "Market"
        );
        setRegionLabel(
          findTranslation("region") !== ""
            ? findTranslation("region")
            : "region"
        );
        setInternalMessageLabel(
          findTranslation("internal_message") !== ""
            ? findTranslation("internal_message")
            : "Internal Message"
        );
        setGroundRulesLabel(
          findTranslation("ground_rules") !== ""
            ? findTranslation("ground_rules")
            : "Ground Rules"
        );
        setLastPublishedLabel(
          findTranslation("last_published_on") !== ""
            ? findTranslation("last_published_on")
            : "Last Published On"
        );
        setLastUpdatedLabel(
          findTranslation("last_updated_on") !== ""
            ? findTranslation("last_updated_on")
            : "Last Updated On"
        );
        setFMVCalculatorLabel(
          findTranslation("fmv_calculator") !== ""
            ? findTranslation("fmv_calculator")
            : "FMV Calculator"
        );
        setFMVHourlyRateCardLabel(
          findTranslation("fmv_hourly_rate_card") !== ""
            ? findTranslation("fmv_hourly_rate_card")
            : "FMV Hourly Rate Card"
        );
        // setKOLAssessmentLabel(
        //   findTranslation("kol_assessment") !== ""
        //     ? findTranslation("kol_assessment")
        //     : "KOL Assessment"
        // );
        // setKOLDefinitionLabel(
        //   findTranslation("kol_definition") !== ""
        //     ? findTranslation("kol_definition")
        //     : "KOL Definition"
        // );
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transLanguageId]);

  useEffect(() => {
    if (calculatorData) {
      if (calculatorData?.data?.basic) {
        if (preview) {
          if (preview.toLowerCase() === "preview") {
            setDisclaimerAcknowledged(true);
            setRegionId(calculatorData?.data?.basic?.region_id);
            setCountryId(calculatorData?.data?.basic?.country_id);
            setSectorId(calculatorData?.data?.sectors[0]?.sector_id);
            if (calculatorData?.data?.basic?.version) {
              if (calculatorData?.data?.basic?.version.split(".")[1] !== "0") {
                setIsDraft(true);
              }
            }
          }
        }
        setIsNoCalculator(false);
        setNoCalculatorMessage("");

        setCalcName(calculatorData?.data?.basic?.name);
        setCalcRegion(calculatorData?.data?.basic?.region);
        if (preview) {
          if (preview.toLowerCase() === "preview") {
            let version =
              calculatorData?.data?.basic?.version.split(".")[0] === "0"
                ? `Version 1 ${
                    calculatorData?.data?.basic?.version.split(".")[1] === "0"
                      ? ".0"
                      : "Draft " +
                        calculatorData?.data?.basic?.version.split(".")[1]
                  }`
                : `Version ${
                    calculatorData?.data?.basic?.version.split(".")[1] !== "0"
                      ? parseInt(
                          calculatorData?.data?.basic?.version.split(".")[0]
                        ) + 1
                      : parseInt(
                          calculatorData?.data?.basic?.version.split(".")[0]
                        )
                  }  ${
                    calculatorData?.data?.basic?.version.split(".")[1] === "0"
                      ? ".0"
                      : "Draft " +
                        calculatorData?.data?.basic?.version.split(".")[1]
                  }`;
            setCalcVersion(version);
          }
        } else {
          setCalcVersion(`Version ${calculatorData?.data?.basic?.version}`);
        }

        if (calculatorData?.data?.basic?.last_updated_date) {
          let date = new Date(calculatorData?.data?.basic?.last_updated_date);
          setLastPublished(
            monthsList.current[date.getMonth()] +
              " " +
              date.getDate() +
              ", " +
              date.getFullYear()
          );
        }
        if (calculatorData?.data?.sectors) {
          setCalcSectors(
            _.map(calculatorData?.data?.sectors, o => {
              return o.sector;
            }).join(", ")
          );
        }
        if (calculatorData?.data?.translations) {
          let transId = _.find(calculatorData?.data?.translations, o => {
            return o?.isDefault && o?.isDefault !== null;
          })?.id;
          if (transId !== "" && transId !== undefined) {
            setTransLanguageId(transId);
          }
          setTranslationsData(calculatorData?.data?.translations);
          let List = calculatorData?.data?.translations?.map((obj, i) => {
            return (
              <option key={i} value={obj.id}>
                {obj.name}
              </option>
            );
          });
          setTranslationsListObj(List);
        }
        setInternalMessage(calculatorData?.data?.messages?.messages);
        setGroundRules(calculatorData?.data?.groundRules);
        setIsKOLApplicable(
          // calculatorData?.data?.basic.is_kol_assessment_applicable !== null
          calculatorData?.data?.basic.is_kol_assessment_applicable === true
            ? true
            : false
        );
        setIsTranslationAvailable(
          // calculatorData?.data?.basic.is_translation_available !== null
          calculatorData?.data?.basic.is_translation_available === true
            ? true
            : false
        );
      } else {
        setIsNoCalculator(true);
        setNoCalculatorMessage(calculatorData?.data);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculatorData, isDraft]);

  useEffect(() => {
    if (calcVersion !== undefined && calcVersion !== "") {
      dispatch(setCalculatorHeaderData(calcVersion));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calcVersion]);

  useEffect(() => {
    if (isDraft) {
      dispatch(setDraftVersionStatus("draftversion"));
    } else if (!isDraft) {
      dispatch(setDraftVersionStatus(""));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDraft, calculatorData]);

  useEffect(() => {
    if (
      countryId !== undefined &&
      countryId !== "" &&
      sectorId !== undefined &&
      sectorId !== ""
    ) {
      if (preview) {
        if (preview.toLowerCase() !== "preview") {
          let data = {
            country_id: countryId,
            sector_id: sectorId
          };
          rateCardData.current = data;
        } else {
          let calcId = param1;
          let version = param2 ? param2 : "";
          let data = {
            preview: true,
            calc_id: calcId,
            ...(version !== "" && version ? { version: version } : {})
          };
          rateCardData.current = data;
        }
      } else {
        let data = {
          country_id: countryId,
          sector_id: sectorId
        };
        rateCardData.current = data;
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryId, sectorId]);

  useEffect(() => {
    if (
      countryObj !== undefined &&
      sectorObj !== undefined &&
      countryObj !== "" &&
      sectorObj !== ""
    ) {
      let data = {
        country_name: countryObj,
        sector_name: sectorObj
      };
      dispatch(setCountrySectorNameData(data));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryObj, sectorObj]);

  const DropDownComp = props => {
    if (props.listObj !== undefined) {
      return (
        <select
          className="select-config"
          value={props.objId}
          onChange={e => props.callOnChange(e.target.value)}
        >
          <option value="">{props.holderName}</option>
          {props.listObj}
        </select>
      );
    }
    return (
      <select>
        <option>Select</option>
      </select>
    );
  };

  const ResetValues = () => {
    setDisclaimerAcknowledged(false);
    setCalcName("");
    setCalcRegion("");
    setLastPublished("");
    setInternalMessage("");
    setGroundRules([]);
  };

  const OnSelectCountry = value => {
    dispatch(resetCalculatorData({}));

    setCountryId(value?.name);
    setSectorId("");
    setSectorObj("");
    setCountryObj(value);
    setSectorsListObj([]);
    setShowDisclaimer(false);

    if (value?.name && value?.name !== "") {
      setShowSectorOption(true);
    } else {
      setShowSectorOption(false);
    }
    ResetValues();
  };

  const OnSelectRegion = value => {
    dispatch(resetCalculatorData({}));

    setRegionId(value?.name);
    setRegionObj(value);
    setCountryId("");
    setCountry("");
    setCountriesListObj([]);
    setShowSectorOption(false);
    setShowDisclaimer(false);
    setCountryObj("");
    ResetValues();
  };

  const OnSelectSector = value => {
    dispatch(resetCalculatorData({}));

    setSectorId(value?.name);
    setSectorObj(value);
    setShowDisclaimer(value?.name && value?.name !== "" ? true : false);
    ResetValues();
  };

  const OnSelectLanguage = value => {
    setTransLanguageId(value);
  };

  const GroundRulesList = () => {
    const rows = groundRules.map((obj, i) => {
      return <li key={i}>{obj.rule_desc}</li>;
    });
    return <ul>{rows}</ul>;
  };

  const OnAcknowledgeDisclaimer = e => {
    if (e.target.checked) {
      setDisclaimerAcknowledged(true);
      let data = {
        country_id: countryId,
        sector_id: sectorId
      };
      dispatch(fetchCalculatorData(data));
    } else {
      ResetValues();
    }
  };

  const GenerateShareableLink = () => {
    let link =
      window.location.origin +
      "/fmvcalculator/view/" +
      countryId +
      "/" +
      sectorId;

    Swal.fire({
      title: "<div><h5 style='float: left;'>Link to the Calculator</h5></div>",
      html:
        "<div style='border: solid 1px darkgray;'><h6 style='margin: 8px; text-align: left; font-size: 14px;'>" +
        link +
        "</h6></div>",
      width: 900,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#808080",
      focusConfirm: false,
      confirmButtonText: "Copy Link",
      cancelButtonText: "Close",
      showClass: {
        popup: "animate__animated animate__fadeInDown"
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp"
      }
    }).then(result => {
      if (result.isConfirmed) {
        navigator.clipboard.writeText(link);
        // window.open(link, '_blank').focus();
      }
    });
  };

  const MapSectors = () => {
    if (!isPreview.current) {
      let sectorsToMap = _.map(
        _.filter(calculatorData?.data?.sectors, o => {
          return o.sector.toLowerCase() !== sectorObj.label.toLowerCase();
        }),
        obj => {
          return obj.sector;
        }
      ).join(", ");

      return sectorsToMap && sectorsToMap !== null && sectorsToMap !== "" ? (
        <label>
          <label className="font-bold">{sectorObj.label}, </label>{" "}
          {sectorsToMap}
        </label>
      ) : (
        <label>
          <label className="font-bold">{sectorObj.label}</label>
        </label>
      );
    } else {
      return <label>{calcSectors}</label>;
    }
  };

  return (
    <div className="calculator">
      <ShowLoader></ShowLoader>
      <div>
        <Container className="calculator-container">
          {!isPreview.current ? (
            <Row>
              <Col md={12}>
                <h4>HCP FFS FMV Calculator</h4>
              </Col>
            </Row>
          ) : (
            ""
          )}

          {!isPreview.current ? (
            <Row>
              <Col className="label-name auto-search-label" md={2}>
                Please Select Region
              </Col>
              <Col md={3}>
                <Select
                  onChange={OnSelectRegion}
                  options={regionListObj}
                  value={regionObj}
                  getOptionValue={option => option.name}
                  placeholder={"All Regions"}
                  isDisabled={isView}
                />
              </Col>
            </Row>
          ) : (
            ""
          )}
          {!isPreview.current ? (
            <Row>
              <Col className="label-name auto-search-label" md={2}>
                Please Select Market
                <span className="asterisk-mandatory">*</span>
              </Col>
              <Col md={3}>
                <Select
                  onChange={OnSelectCountry}
                  options={countriesListObj}
                  value={countryObj}
                  getOptionValue={option => option.name}
                  placeholder={"Select(Type to search)"}
                  isDisabled={isView}
                />
              </Col>
            </Row>
          ) : (
            ""
          )}
          {showSectorOption && !isPreview.current ? (
            <Row>
              <Col className="label-name auto-search-label" md={2}>
                Please Select Sector
                <span className="asterisk-mandatory">*</span>
              </Col>
              <Col md={3}>
                <Select
                  onChange={OnSelectSector}
                  options={sectorsListObj}
                  placeholder={"Select(Type to search)"}
                  value={sectorObj}
                  getOptionValue={option => option.name}
                  isDisabled={isView}
                />
              </Col>
            </Row>
          ) : (
            ""
          )}
          {showDisclaimer ? (
            <Container className="disclaimer">
              {!isPreview.current ? (
                <Row>
                  <Col md={12}>
                    <h5>Disclaimer</h5>
                  </Col>
                </Row>
              ) : (
                ""
              )}

              {!isPreview.current ? (
                <Row>
                  <Col md={12}>
                    <RenderRichText html={disclaimer} />
                    <div className="input-check">
                      <input
                        type="checkbox"
                        id="chkDisclaimer"
                        onChange={OnAcknowledgeDisclaimer}
                        checked={disclaimerAcknowledged}
                        disabled={isView}
                      />
                      <label htmlFor="chkDisclaimer">I Acknowledge</label>
                    </div>
                  </Col>
                </Row>
              ) : (
                ""
              )}
            </Container>
          ) : (
            ""
          )}
          {disclaimerAcknowledged && isNoCalculator ? (
            <Row>
              <Col className="no-data">{noCalculatorMessage}</Col>
            </Row>
          ) : (
            ""
          )}
          {disclaimerAcknowledged && calcName && calcName !== null ? (
            <Container className="calc-name-version">
              <div>
                {/* {isAdmin && showGenerateLink ? ( */}
                {showGenerateLink ? (
                  <div
                    className="calc_config_history_link share-link"
                    onClick={GenerateShareableLink}
                  >
                    Generate Shareable Link
                  </div>
                ) : (
                  ""
                )}
              </div>
              {!isPreview.current ? (
                <Row>
                  <Col md={12}>
                    <div className="separator"></div>
                  </Col>
                </Row>
              ) : (
                ""
              )}
              {isTranslationAvailable ? (
                <Row>
                  <Col />
                  <Col md={2}>
                    <DropDownComp
                      listObj={translationsListObj}
                      callOnChange={OnSelectLanguage}
                      objId={transLanguageId}
                      holderName={"Select Language"}
                    ></DropDownComp>
                  </Col>
                </Row>
              ) : (
                ""
              )}
              <Row id="calculator-draft-version-header-id">
                <Col className="draft-text">
                  {isDraft === true ? <h2>DRAFT VERSION</h2> : ""}
                </Col>
              </Row>
              <Row id="calculator-header-info">
                <Col md={6} className="calculator-name">
                  <h4>{calcName}</h4>
                  <div className="version-detail">
                    HCP FFS FMV Calculator {calcVersion} -{" "}
                    {isDraft === true ? lastUpdatedLabel : lastPublishedLabel}:{" "}
                    {lastPublished}
                  </div>
                </Col>
                <Col className="label-name align-right" md={6}>
                  <div>
                    {regionLabel}: {calcRegion} &nbsp; {countryLabel}: {country}
                  </div>
                  <div>
                    {sectorLabel}: {MapSectors()}
                  </div>
                </Col>
              </Row>
              <Row id="pdf-head-calculator-header-info" className="display-text-for-pdf pdf_version_sector_section">
                <Row className="pdf_header_calc_name pdf_version_sector_section">
                  <Col md={12} className="calculator-name">
                    <h6>{calcName}</h6>
                  </Col>
                </Row>
                <Row className="pdf_version_sector_section">
                  <Col md={6}>
                    <div className="pdf_header_version-detail">
                      HCP FFS FMV Calculator {calcVersion} -{" "}
                      {isDraft === true ? lastUpdatedLabel : lastPublishedLabel}:{" "}
                      {lastPublished}
                    </div>
                  </Col>
                  <Col className="label-name align-right" md={6}>
                    <div>
                      {regionLabel}: {calcRegion} &nbsp; {countryLabel}: {country}
                    </div>
                  </Col>
                </Row>
                <Row className="pdf_version_sector_section">
                  <Col className="label-name align-right" md={12}>
                    <div>
                      {sectorLabel}: {MapSectors()}
                    </div>
                  </Col>
                </Row>
              </Row>
            </Container>
          ) : (
            ""
          )}
          {disclaimerAcknowledged &&
          internalMessage &&
          internalMessage !== null &&
          internalMessage.toLowerCase() !== "no message" ? (
            <Container className="calc-internal-mssg">
              <Row>
                <Col md={12} className="internal-mssg">
                  <h5>{internalMessageLabel}</h5>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <RenderRichText html={internalMessage} />
                </Col>
              </Row>
            </Container>
          ) : (
            ""
          )}
          {disclaimerAcknowledged && groundRules && groundRules.length > 0 ? (
            <Container className="calc-ground-rules">
              <Row>
                <Col md={12}>
                  <h5>{groundRulesLabel}</h5>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <GroundRulesList></GroundRulesList>
                </Col>
              </Row>
            </Container>
          ) : (
            ""
          )}
        </Container>

        <Container>
          <Row>
            <Col>
              {disclaimerAcknowledged && !isNoCalculator ? (
                <Tabs defaultActiveKey="fmvcalculator" id="calculator-tab">
                  <Tab eventKey="fmvcalculator" title={FMVCalculatorLabel}>
                    <FMVCalculator
                      globalCapMessage={globalCapMssg}
                    ></FMVCalculator>
                  </Tab>
                  <Tab eventKey="rateCard" title={FMVHourlyRateCardLabel}>
                    <FMVRateCard
                      rateCardDataToProcess={rateCardData.current}
                    ></FMVRateCard>
                  </Tab>
                  {isKOLApplicable ? (
                    <Tab eventKey="kolAssessment" title={"KOL Assessment"}>
                      <FMVKOLAssessment></FMVKOLAssessment>
                    </Tab>
                  ) : (
                    ""
                  )}
                  {/* {isKOLApplicable ? ( */}
                  <Tab eventKey="kolDefinition" title={"KOL Definition"}>
                    <FMVKolDefinition></FMVKolDefinition>
                  </Tab>
                  {/* ) : (
                    ""
                  )} */}
                </Tabs>
              ) : (
                ""
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
