import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, Redirect } from "react-router-dom";
import { CalculatorConfigBasic } from "./calcConfigBasic";
import { HCPSegment } from "./hcpSegment";
import { HCPStature } from "./hcpStature";
import { CountrySpecificLimit } from "./countrySpecificLimit";
import { ActivityType } from "./activityType";
import { ActivityLocation } from "./activityLocation";
import { GroundRules } from "./groundRules";
import { HCPSpecialty } from "./hcpSpecialty";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, Tabs, Tab, Button } from "react-bootstrap";
import Multiselect from "multiselect-react-dropdown";
import Swal from "sweetalert2";
import { ShowLoader } from "../helper/showLoader";
import {
  fetchCalcConfigData,
  fetchMasterData,
  setCalcConfigMain,
  checkDuplicate,
  saveCalcConfigData,
  updateCalcConfigData,
  saveAsNewCalculatorData,
  setCalcConfigBasicData,
  setIsDataProcessedForSaveAsNew
} from "../actions/calcConfig";
import _ from "lodash";

export function CalculatorConfiguration() {
  let { calculatorid } = useParams();
  let { version } = useParams();

  const monthsList = useRef([
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ]);

  const calcChildRef = useRef([]);
  const sectorsListObj = useRef();
  const regionsListObj = useRef();
  const allVersionsList = useRef();
  const calcValidationMssg = useRef("");
  const postMethod = useRef("");
  const updatedOn = useRef("");
  const updatedBy = useRef("");
  const isDraft = useRef(true);
  const isPublish = useRef(false);
  const isUnpublish = useRef(false);
  const isUnpublishToPublish = useRef(false);
  const redirectUrl = useRef("");
  const isSaveAsDraft = useRef(false);
  const accessUrl = useRef("");
  const isLastVersion = useRef(false);
  const isLastPublishedVersion = useRef(false);
  const isLastUnpublishedVersion = useRef(false);
  const isDraftDeleted = useRef(false);
  const calcConfigBasicId = useRef("");
  const isDraftDelete = useRef(false);
  const isRegionId = useRef(false);
  const isHCCOorAuditor = useRef(false);

  const [calcName, setCalcName] = useState("");
  const [sectorIds, setSectorIds] = useState([]);
  const [regionId, setRegionId] = useState("");
  const [countryId, setCountryId] = useState("");
  const [countrieslist, setCountrieslist] = useState([]);
  const [calcStatus, setCalcStatus] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [calcVersion, setCalcVersion] = useState("");
  const [selectedVersion, setSelectedVersion] = useState("");
  const [changedVersion, setChangedVersion] = useState("");
  const [endUserStatus, setEndUserStatus] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isRedirect, setIsRedirect] = useState(false);
  const [validationMessage, setValidationMessage] = useState([]);
  const [showNameBorder, setShowNameBorder] = useState(false);
  const [showRegionBorder, setShowRegionBorder] = useState(false);
  const [showCountryBorder, setShowCountryBorder] = useState(false);
  const [showSectorBorder, setShowSectorBorder] = useState(false);
  const [isAccess, setIsAccess] = useState(false);
  const [showCalcHistory, setShowCalcHistory] = useState(false);
  const [isSaveNewCalc, setIsSaveNewCalc] = useState(false);
  const [calculatorIdValue, setCalculatorIdValue] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [adminToolSelected, setAdminToolSelected] = useState("");

  const calcConfig = useSelector(state => state.calcConfig);
  const calcConfiguration = useSelector(
    state => state.calcConfig?.calcConfigData?.data
  );
  const sectorsMaster = useSelector(
    state => state.calcConfig.sectorsMasterData
  );
  const regionsMaster = useSelector(
    state => state.calcConfig.regionsMasterData
  );
  const countryMaster = useSelector(
    state => state.calcConfig.countryMasterData
  );
  const languageMaster = useSelector(
    state => state.calcConfig.languageMasterData
  );
  const percentileMaster = useSelector(
    state => state.calcConfig.percentileMasterData
  );
  const currencyMaster = useSelector(
    state => state.calcConfig.currencyMasterData
  );
  const segmentsMaster = useSelector(
    state => state.calcConfig.segmentsMasterData
  );
  const statureMaster = useSelector(
    state => state.calcConfig.hcpStatureMasterData
  );
  const travelDistanceMaster = useSelector(
    state => state.calcConfig.travelDistanceMasterData
  );
  const activityTypeMaster = useSelector(
    state => state.calcConfig.activityTypeMasterData
  );
  const specialtyMaster = useSelector(
    state => state.calcConfig.hcpSpecialtyMasterData
  );
  const specialtyGroupMaster = useSelector(
    state => state.calcConfig.hcpSpecialtyGroupMasterData
  );

  const duplicateResponse = useSelector(
    state => state.calcConfig.duplicateCount
  );
  const isSaving = useSelector(state => state.calcConfig.isSaving);
  const saveAsNew = useSelector(state => state.calcConfig.saveAsNew);
  const saveAsNewProcessed = useSelector(
    state => state.calcConfig.saveAsNewProcessed
  );
  const dataUpdated = useSelector(state => state.calcConfig.dataUpdated);
  const updatedData = useSelector(state => state.calcConfig.updatedData);
  const userInformation = useSelector(state => state.userInformation);

  const dispatch = useDispatch();

  useEffect(() => {
    if (userInformation !== undefined && userInformation?.data !== undefined) {
      let systemadmin = false,
        businessadmin = false;
      _.map(userInformation?.data?.users[0]?.roles, o => {
        if (o?.role?.includes("System")) {
          systemadmin = true;
        } else if (o?.role?.includes("HCCO") || o?.role?.includes("Auditor")) {
          isHCCOorAuditor.current = systemadmin || businessadmin ? false : true;
        }
      });
      _.map(userInformation?.data?.users[0]?.roles, o => {
        if (o?.role?.includes("Business")) {
          businessadmin = true;
        } else if (o?.role?.includes("HCCO") || o?.role?.includes("Auditor")) {
          isHCCOorAuditor.current = systemadmin || businessadmin ? false : true;
        }
      });
      _.map(userInformation?.data?.users[0]?.roles, o => {
        if (o?.role?.includes("HCCO") || o?.role?.includes("Auditor")) {
          isHCCOorAuditor.current = systemadmin || businessadmin ? false : true;
        }
      });
      if (!systemadmin) {
        if (businessadmin) {
          if (
            userInformation?.data?.users[0]?.regionId !== undefined &&
            !isEditing
          ) {
            setRegionId(userInformation?.data?.users[0]?.regionId);
            isRegionId.current = true;
          }
        }
      }
      let pagesAccess = userInformation?.data?.users[0]?.accessPages;
      if (pagesAccess !== undefined) {
        _.map(pagesAccess, o => {
          if (o === "Calculator configuration") {
            if (systemadmin || businessadmin) {
              setIsAccess(true);
              setShowCalcHistory(true);
            } else if (isHCCOorAuditor.current && isEditing) {
              setIsAccess(true);
            }
          }
        });
        accessUrl.current = "/accessdenied";
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInformation]);

  useEffect(() => {
    dispatch(fetchMasterData("sectors"));
    dispatch(fetchMasterData("regions"));
    dispatch(fetchMasterData("countries"));
    dispatch(fetchMasterData("languages"));
    dispatch(fetchMasterData("percentiles"));
    dispatch(fetchMasterData("currencies"));
    dispatch(fetchMasterData("segments"));
    dispatch(fetchMasterData("hcp_statures"));
    dispatch(fetchMasterData("travel_distances"));
    dispatch(fetchMasterData("activity_types"));
    dispatch(fetchMasterData("hcp_specialities"));
    dispatch(fetchMasterData("hcp_speciality_list"));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (calculatorid !== undefined) {
      setIsEditing(true);
      setCalculatorIdValue(calculatorid);
      setSelectedVersion(version);
      dispatch(fetchCalcConfigData(calculatorid, version));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculatorid, version]);

  useEffect(() => {
    if (sectorsMaster !== undefined) {
      sectorsListObj.current = _.map(sectorsMaster?.data, obj => {
        return { id: obj.id, name: obj.value };
      });
    }
  }, [sectorsMaster]);

  useEffect(() => {
    if (regionsMaster !== undefined) {
      regionsListObj.current = regionsMaster?.data.map((obj, i) => {
        return (
          <option
            key={i}
            value={obj.id}
            id={"calcConfig-region-option-" + obj.value}
          >
            {obj.value}
          </option>
        );
      });
    }
  }, [regionsMaster]);

  useEffect(() => {
    if (
      regionId !== undefined &&
      regionId !== "" &&
      countryMaster !== undefined
    ) {
      let filteredCountries = _.filter(
        countryMaster.data,
        o => {
          return o.region_id === regionId;
        },
        0
      );

      let list = filteredCountries.map((obj, i) => {
        return (
          <option
            key={i}
            value={obj.id}
            id={"calcConfig-country-option-" + obj.country}
          >
            {obj.country}
          </option>
        );
      });
      setCountrieslist(list);
    } else {
      setCountrieslist([]);
    }
  }, [regionId, countryMaster]);

  useEffect(() => {
    if (calcConfiguration !== undefined && isEditing) {
      setCalcName(calcConfiguration?.calcConfig?.name);
      setRegionId(calcConfiguration?.calcConfig?.region_id);
      setCountryId(calcConfiguration?.calcConfig?.country_id);

      let updateAt = new Date(calcConfiguration?.calcConfigBasic?.updated_at);

      isDraftDeleted.current =
        calcConfiguration?.calcConfigBasic?.status === "DISABLED"
          ? true
          : false;

      calcConfigBasicId.current = calcConfiguration?.calcConfigBasic?.item_id;

      updatedOn.current =
        updateAt.toLocaleString("default", { month: "short" }) +
        " " +
        updateAt.getDate() +
        ", " +
        updateAt.getFullYear();

      updatedBy.current = calcConfiguration?.calcConfigBasic?.updated_by;

      let selectedSectors = [];
      _.forEach(calcConfiguration?.calcConfig?.sector_ids, function(value) {
        selectedSectors.push(
          _.find(sectorsListObj.current, obj => {
            return obj.id === value;
          })
        );
      });
      setSectorIds(selectedSectors);

      _.map(calcConfiguration?.versionAndSaveStatus, (obj, index) => {
        if (
          obj?.actualVersion === calcConfiguration?.calcConfigBasic?.version
        ) {
          setCalcStatus(obj?.versionToShow);
          setCalcVersion(obj?.actualVersion);
        }
      });
      let firstVersionItem = _.first(calcConfiguration?.versionAndSaveStatus);
      if (
        firstVersionItem.actualVersion ===
        calcConfiguration?.calcConfigBasic?.version
      ) {
        isLastVersion.current = true;
      }
      let publishedVersions = _.filter(
        calcConfiguration?.versionAndSaveStatus,
        obj => {
          return obj.versionToShow.includes("PUBLISHED");
        }
      );
      setEndUserStatus(calcConfiguration?.calcConfig?.end_user_status);
      let firsttPublishedItem = _.first(publishedVersions);
      if (firsttPublishedItem) {
        if (
          calcConfiguration?.calcConfigBasic.version ===
          firsttPublishedItem.actualVersion
        ) {
          isLastPublishedVersion.current = true;
          if (
            calcConfiguration?.calcConfig?.end_user_status === "UNPUBLISHED"
          ) {
            isLastUnpublishedVersion.current = true;
            setCalcStatus("UNPUBLISHED");
          }
        }
      }
      allVersionsList.current = _.orderBy(
        _.uniq(_.map(calcConfiguration?.versionAndSaveStatus))
      ).map((obj, i) => {
        return (
          <option
            key={i}
            value={obj.actualVersion}
            id={"calcConfig-version-option-" + obj.versionToShow}
          >
            {obj.versionToShow}
          </option>
        );
      });
    }
  }, [calcConfiguration, isEditing]);

  useEffect(() => {
    if (calcConfiguration !== undefined && saveAsNew) {
      setCalcName("");
      setRegionId("");
      setCountryId("");
      setSectorIds([]);
      setCalcStatus("");
      setCalcVersion("");
      setEndUserStatus("");
      setCalcStatus("");

      isDraftDeleted.current = false;
      calcConfigBasicId.current = "";
      updatedOn.current = "";
      updatedBy.current = "";

      isLastVersion.current = false;
      isLastPublishedVersion.current = false;
      isLastUnpublishedVersion.current = false;
      allVersionsList.current = [];

      calcConfiguration.calcConfig = {};
      calcConfiguration.calcConfigSectors = {};
      calcConfiguration.versionAndSaveStatus = {};

      let propsToDelete = [
        "item_id",
        "calc_config_id",
        "version",
        "status",
        "save_status",
        "last_updated_date",
        "published_date",
        "updated_at",
        "updated_by"
      ];
      _.forEach(propsToDelete, o => {
        delete calcConfiguration.calcConfigBasic[o];
      });
      dispatch(setCalcConfigMain(calcConfiguration.calcConfig));
      dispatch(setCalcConfigBasicData(calcConfiguration.calcConfigBasic));
      dispatch(
        setIsDataProcessedForSaveAsNew({
          mainProcessed: true,
          basicProcessed: true
        })
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calcConfiguration, saveAsNew]);

  useEffect(() => {
    if (saveAsNewProcessed !== undefined) {
      if (
        saveAsNewProcessed.mainProcessed &&
        saveAsNewProcessed.basicProcessed &&
        saveAsNewProcessed.hcpSegmentsProcessed &&
        saveAsNewProcessed.hcpStaturesProcessed &&
        saveAsNewProcessed.countrySpecLimitProcessed &&
        saveAsNewProcessed.activityTypesProcessed &&
        saveAsNewProcessed.activityLocationProcessed &&
        saveAsNewProcessed.groundRulesAndMessagesProcessed &&
        saveAsNewProcessed.hcpSpecialtyProcessed
      ) {
        dispatch(saveAsNewCalculatorData(false));
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveAsNewProcessed]);

  useEffect(() => {
    if (changedVersion !== undefined && changedVersion !== "") {
      redirectUrl.current =
        "/calculatorconfig/" + calculatorid + "/" + changedVersion;
      window.location.href = redirectUrl.current;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changedVersion]);

  useEffect(() => {
    let configMain = {
      ...(calculatorid !== undefined && calculatorid !== ""
        ? { item_id: calculatorid }
        : {}),
      name: calcName,
      country_id: countryId,
      region_id: regionId,
      sector_ids: isEditing ? _.flatMap(sectorIds, "id") : sectorIds
    };
    dispatch(setCalcConfigMain(configMain));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calcName, sectorIds, regionId, countryId]);

  useEffect(() => {
    if (duplicateResponse !== undefined) {
      if (duplicateResponse.count > 0) {
        if (duplicateResponse.calcId !== calculatorid || !calculatorid) {
          let countryName = _.find(countryMaster.data, obj => {
            return obj.id === duplicateResponse.country_id;
          }).country;
          let sectorName = "";
          duplicateResponse.sector_ids.forEach(e => {
            sectorName =
              sectorName === ""
                ? _.find(sectorsListObj.current, obj => {
                    return obj.id === e;
                  }).name
                : sectorName +
                  ", " +
                  _.find(sectorsListObj.current, obj => {
                    return obj.id === e;
                  }).name;
          });
          let mssg = "";
          if (duplicateResponse.sameCombination) {
            let save_status =
              duplicateResponse.save_status.charAt(0) +
              duplicateResponse.save_status.slice(1).toLowerCase();
            calcValidationMssg.current = `A ${save_status} Calculator is already Present for ${countryName} - ${sectorName}, please work on that ${save_status} calculator`;
            // calcValidationMssg.current =
            //   `A Calculator is already ${draftOrPublishedText} for ` +
            //   countryName +
            //   " - " +
            //   sectorName +
            //   ".";
            mssg =
              "<strong style='color: red'>" +
              calcValidationMssg.current +
              "</strong>";
            Swal.fire("", mssg, "warning");
            return;
          } else if (
            duplicateResponse.save_status.toUpperCase() === "PUBLISHED"
          ) {
            calcValidationMssg.current =
              "A Calculator is already Published for " +
              countryName +
              " - " +
              sectorName +
              ".";

            mssg =
              "<h6 style='color: red'>" +
              calcValidationMssg.current +
              "</h6><h6>If you still want to save the configuration, it will be saved as a new Draft Version. Note that at the time of calculator publishing, the other calculator must be unpublished. Do you want to continue?</h6>";

            Swal.fire({
              html: mssg,
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#808080",
              focusConfirm: false,
              confirmButtonText: "Yes",
              showClass: {
                popup: "animate__animated animate__fadeInDown"
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutUp"
              }
            }).then(result => {
              if (result.isConfirmed) {
                isPublish.current = false;
                isUnpublishToPublish.current = false;
                saveAsNewDraftCalculator();
              }
            });
          } else {
            if (isDraft.current) {
              calcValidationMssg.current =
                "A Draft Calculator for " +
                countryName +
                " - " +
                sectorName +
                " already exists.";
              mssg =
                "<h6 style='color: red'>" +
                calcValidationMssg.current +
                "</h6><h6>If you still want to save the configuration, it will be saved as a new Draft Version. Do you want to continue?</h6>";

              Swal.fire({
                html: mssg,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#808080",
                focusConfirm: false,
                confirmButtonText: "Yes",
                showClass: {
                  popup: "animate__animated animate__fadeInDown"
                },
                hideClass: {
                  popup: "animate__animated animate__fadeOutUp"
                }
              }).then(result => {
                if (result.isConfirmed) {
                  // getAllTabData();
                  // dispatch(saveCalcConfigData(true));
                  isPublish.current = false;
                  saveAsNewDraftCalculator();
                }
              });
            } else {
              getAllTabData();
              dispatch(saveCalcConfigData(true));
            }
          }
        } else {
          getAllTabData();
          dispatch(saveCalcConfigData(true));
        }
      } else {
        getAllTabData();
        dispatch(saveCalcConfigData(true));
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duplicateResponse]);

  useEffect(() => {
    if (isSaving) {
      dispatch(saveCalcConfigData(false));
      let dataToProcess = !isUnpublish.current ? formatDataToSave() : {};

      if (isPublish.current) {
        dispatch(
          updateCalcConfigData(
            postMethod.current,
            dataToProcess,
            calculatorid,
            isUnpublish.current
          )
        );
      } else if (isUnpublishToPublish.current) {
        dispatch(
          updateCalcConfigData(
            postMethod.current,
            dataToProcess,
            calculatorid,
            isUnpublish.current,
            isUnpublishToPublish.current
          )
        );
      } else if (isDraftDelete.current) {
        dispatch(
          updateCalcConfigData(
            postMethod.current,
            dataToProcess,
            calcConfigBasicId.current,
            isUnpublish.current
          )
        );
      } else if (validateSave()) {
        dispatch(
          updateCalcConfigData(
            postMethod.current,
            dataToProcess,
            calculatorid,
            isUnpublish.current
          )
        );
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaving]);

  const validateSave = () => {
    let isvalid = true;
    let validationMessages = [];
    if (!calcName || !regionId || !countryId || sectorIds.length === 0) {
      let name = "",
        region = "",
        country = "",
        sector = "";
      if (!calcName) {
        name = "Name, ";
        setShowNameBorder(true);
      } else {
        setShowNameBorder(false);
      }
      if (!regionId) {
        region = "Region, ";
        setShowRegionBorder(true);
      } else {
        setShowRegionBorder(false);
      }
      if (!countryId) {
        country = "Country, ";
        setShowCountryBorder(true);
      } else {
        setShowCountryBorder(false);
      }
      if (sectorIds.length === 0) {
        sector = "Sector, ";
        setShowSectorBorder(true);
      } else {
        setShowSectorBorder(false);
      }
      isvalid = false;
      validationMessages.push({
        msg:
          "Calculator " +
          name +
          region +
          country +
          sector +
          "cannot be blank in Calculator Configuration."
      });
    } else if (calcName && regionId && countryId && sectorIds.length > 0) {
      setShowNameBorder(false);
      setShowCountryBorder(false);
      setShowSectorBorder(false);
      setShowRegionBorder(false);
    }
    if (!isvalid) {
      document.documentElement.scrollTop = 0;
    }
    setValidationMessage(validationMessages);
    return isvalid;
  };

  const validate = () => {
    let isvalid = true;
    let validationMessages = [];
    if (!calcName || !regionId || !countryId || sectorIds.length === 0) {
      let name = "",
        region = "",
        country = "",
        sector = "";
      if (!calcName) {
        name = "Name, ";
        setShowNameBorder(true);
      } else {
        setShowNameBorder(false);
      }
      if (!regionId) {
        region = "Region, ";
        setShowRegionBorder(true);
      } else {
        setShowRegionBorder(false);
      }
      if (!countryId) {
        country = "Country, ";
        setShowCountryBorder(true);
      } else {
        setShowCountryBorder(false);
      }
      if (sectorIds.length === 0) {
        sector = "Sector, ";
        setShowSectorBorder(true);
      } else {
        setShowSectorBorder(false);
      }
      isvalid = false;
      validationMessages.push({
        msg:
          "Calculator " +
          name +
          region +
          country +
          sector +
          "cannot be blank in Calculator Configuration."
      });
    } else if (calcName && regionId && countryId && sectorIds.length > 0) {
      setShowNameBorder(false);
      setShowCountryBorder(false);
      setShowSectorBorder(false);
      setShowRegionBorder(false);
      document.getElementById("baseRateRedId").style.border = "";
      document.getElementById("commentsRedId").style.borderColor = "";
      document.getElementById("currencyRedId").style.border = "";
      document.getElementById("rangesApplicableId").style.border = "";
      let isRangesApplicable = false,
        isMinRangePercent = false,
        isMaxRangePercent = false;
      if (calcConfig?.calcConfigBasic !== undefined) {
        if (calcConfig?.calcConfigBasic?.ranges_applicable?.length === 0) {
          isRangesApplicable = true;
        } else if (calcConfig?.calcConfigBasic?.ranges_applicable?.length > 0) {
          _.map(calcConfig?.calcConfigBasic?.ranges_applicable, o => {
            if (o?.toLowerCase() === "min") {
              document.getElementById("minRangeRedId").style.borderColor = "";
              if (calcConfig?.calcConfigBasic?.min_range_percentage === 0) {
                isMinRangePercent = false;
              } else {
                isMinRangePercent = true;
              }
            }
            if (o?.toLowerCase() === "max") {
              document.getElementById("maxRangeRedId").style.borderColor = "";
              if (calcConfig?.calcConfigBasic?.max_range_percentage === 0) {
                isMaxRangePercent = false;
              } else {
                isMaxRangePercent = true;
              }
            }
          });
        }
      }
      if (
        (calcConfig?.calcConfigBasic !== undefined &&
          !calcConfig.calcConfigBasic.currency_id) ||
        !calcConfig.calcConfigBasic.base_rate ||
        (!calcConfig.calcConfigBasic.min_range_percentage &&
          isMinRangePercent) ||
        (!calcConfig.calcConfigBasic.max_range_percentage &&
          isMaxRangePercent) ||
        !calcConfig.calcConfigBasic.comments ||
        isRangesApplicable
      ) {
        let currency = "",
          baseRate = "",
          min = "",
          max = "",
          comment = "",
          rangesApplicable = "";
        if (!calcConfig.calcConfigBasic.currency_id) {
          currency = "Currency, ";
          document.getElementById("currencyRedId").style.border =
            "red 1px solid";
        }
        if (!calcConfig.calcConfigBasic.base_rate) {
          baseRate = "Base Rate, ";
          document.getElementById("baseRateRedId").style.border =
            "1px solid red";
        }
        if (isRangesApplicable) {
          rangesApplicable = "Ranges applicable, ";
          document.getElementById("rangesApplicableId").style.border =
            "1px solid red";
        }
        if (
          !calcConfig.calcConfigBasic.min_range_percentage &&
          isMinRangePercent
        ) {
          min = "Minimum Range, ";
          document.getElementById("minRangeRedId").style.borderColor = "red";
        }
        if (
          !calcConfig.calcConfigBasic.max_range_percentage &&
          isMaxRangePercent
        ) {
          max = "Maximum Range, ";
          document.getElementById("maxRangeRedId").style.borderColor = "red";
        }
        if (!calcConfig.calcConfigBasic.comments) {
          comment = "Comments, ";
          document.getElementById("commentsRedId").style.borderColor = "red";
        }
        isvalid = false;
        validationMessages.push({
          msg:
            currency +
            baseRate +
            rangesApplicable +
            min +
            max +
            comment +
            "cannot be blank in General Configuration."
        });
      }
      if (
        !calcConfig?.hcpSegmentMul?.tableData ||
        calcConfig?.hcpSegmentMul?.tableData?.length === 0
      ) {
        validationMessages.push({
          msg: "At least one HCP Segment multiplier should be entered."
        });
        isvalid = false;
      }
      if (
        !calcConfig?.hcpStatureMul?.tableData ||
        calcConfig?.hcpStatureMul?.tableData?.length === 0
      ) {
        validationMessages.push({
          msg: "At least one HCP Stature multiplier should be entered."
        });
        isvalid = false;
      }
      if (
        !calcConfig?.activityTypes?.tableData ||
        calcConfig?.activityTypes?.tableData?.length === 0
      ) {
        validationMessages.push({
          msg: "At least one Activity Type and Caps should be entered."
        });
        isvalid = false;
      }
      if (
        !calcConfig?.travelTimes?.tableData ||
        calcConfig?.travelTimes?.tableData?.length === 0
      ) {
        validationMessages.push({
          msg: "At least one Activity Location - Travel Time should be entered."
        });
        isvalid = false;
      }
      if (
        !calcConfig?.hcpSpecialties?.tableData ||
        calcConfig?.hcpSpecialties?.tableData?.length === 0
      ) {
        validationMessages.push({
          msg: "At least one HCP Specialty should be selected."
        });
        isvalid = false;
      }
    }
    if (!isvalid) {
      document.documentElement.scrollTop = 0;
    }
    setValidationMessage(validationMessages);
    return isvalid;
  };

  useEffect(() => {
    if (dataUpdated) {
      if (isUnpublish.current) {
        Swal.fire({
          html: "<strong>Calculator unpublished successfully.</strong>",
          icon: "success",
          confirmButtonColor: "#3085d6",
          focusConfirm: false,
          confirmButtonText: "OK",
          showClass: {
            popup: "animate__animated animate__fadeInDown"
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp"
          }
        }).then(result => {
          redirectUrl.current = "/calculatordashboard";
          window.location.href = redirectUrl.current;
        });
      } else {
        let mssg = isDraftDelete.current
          ? "<strong>Draft version deleted successfully.</strong>"
          : isPublish.current
          ? "<strong>Calculator published successfully.</strong>"
          : isSaveAsDraft.current
          ? "<strong>Calculator saved as new draft successfully.</strong>"
          : isUnpublishToPublish.current
          ? "<strong>Calculator published again successfully.</strong>"
          : isEditing
          ? "<strong>Calculator updated successfully.</strong>"
          : "<strong>Calculator saved successfully.</strong>";
        Swal.fire({
          html: mssg,
          icon: "success",
          confirmButtonColor: "#3085d6",
          focusConfirm: false,
          confirmButtonText: "OK",
          showClass: {
            popup: "animate__animated animate__fadeInDown"
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp"
          }
        }).then(result => {
          redirectUrl.current = !isDraftDelete.current
            ? "/calculatorconfig/" + updatedData?.data?.calcConfig?.id
            : "/calculatorconfig/" +
              updatedData?.data?.calcConfig?.calc_config_id +
              "/" +
              updatedData?.data?.calcConfig?.version;
          window.location.href = redirectUrl.current;
        });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataUpdated]);

  const formatDataToSave = () => {
    calcConfig.calcConfigBasic.isDraft = isDraft.current;

    let data = {
      calcConfig: calcConfig.calcConfigMain,
      calcConfigBasic: calcConfig.calcConfigBasic,
      ...(calcConfig.hcpSegmentMul !== undefined
        ? { calcConfigHCPSegmentMul: calcConfig.hcpSegmentMul.tableData }
        : {}),
      ...(calcConfig.hcpStatureMul !== undefined
        ? { calcConfigKolStatureMul: calcConfig.hcpStatureMul.tableData }
        : {}),
      ...(calcConfig.countrySpecificLimits !== undefined
        ? { calcCountrySpecificLimit: calcConfig.countrySpecificLimits }
        : {}),
      ...(calcConfig.activityTypes !== undefined
        ? {
            calcConfigActivityTypesHrsAndHrCap:
              calcConfig.activityTypes.tableData
          }
        : {}),
      ...(calcConfig.travelTimes !== undefined
        ? { calcConfigTravelTime: calcConfig.travelTimes.tableData }
        : {}),
      ...(calcConfig.travelTimes !== undefined
        ? {
            calcConfigTravelTimeDiscount:
              calcConfig.travelTimes.travelTimeDiscount
          }
        : {}),
      ...(calcConfig.groundRules !== undefined
        ? { calcConfigGroundRules: calcConfig.groundRules.groundRulesTabledata }
        : {}),
      ...(calcConfig.groundRules !== undefined
        ? { calcConfigMessages: calcConfig.groundRules.messagestabledata }
        : {}),
      ...(calcConfig.hcpSpecialties !== undefined
        ? { calcConfigHcpSpeciality: calcConfig.hcpSpecialties.tableData }
        : {}),
      ...(calcConfig.hcpSpecialties !== undefined
        ? {
            hcpSpecialtyGroupId:
              calcConfig.hcpSpecialties.hcpSpecialtyGroupIdData
          }
        : {}),
      ...(calcConfig.hideMultiplierData !== undefined
        ? {
            hide_speciality_multiplier:
              calcConfig.hideMultiplierData?.hideSpecialityMultiplierData
          }
        : {}),
      ...(calcConfig.hideMultiplierData !== undefined
        ? {
            hide_stature_multiplier:
              calcConfig.hideMultiplierData?.hideStatureMultiplierData
          }
        : {})
    };
    return data;
  };

  const DropDownComp = props => {
    if (props.listObj !== undefined) {
      return (
        <select
          className={
            props?.show ? "select-config cal-val-red" : "select-config"
          }
          value={props.objId}
          onChange={e => props.callOnChange(e.target.value)}
          disabled={props.disabled}
          id={props.selecteId}
        >
          <option value="">{props.holderName}</option>
          {props.listObj}
        </select>
      );
    }
    return (
      <select>
        <option>Select</option>
      </select>
    );
  };

  const onVersionChange = e => {
    setSelectedVersion(e);
    setChangedVersion(e);
  };

  const sectorOnSelect = (selectedList, selectedItem) => {
    setSectorIds(_.flatMap(selectedList, "id"));
  };

  const sectorOnRemove = (selectedList, removedItem) => {
    setSectorIds(_.flatMap(selectedList, "id"));
  };

  const saveCalculator = () => {
    if (validateSave()) {
      if (isEditing) {
        Swal.fire({
          html:
            "<strong>By using Update, the version number will remain the same and changes will not be saved in the audit log. Would you like to proceed?</strong>",
          icon: "info",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#808080",
          focusConfirm: false,
          confirmButtonText: "YES",
          cancelButtonText: "NO",
          showClass: {
            popup: "animate__animated animate__fadeInDown"
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp"
          }
        }).then(result => {
          if (result.isConfirmed) {
            isDraft.current = calcStatus.toUpperCase() === "DRAFT";
            postMethod.current = "PATCH";
            dispatch(saveCalcConfigData(true));
          }
        });
      } else {
        isDraft.current = true;
        postMethod.current = "POST";
        checkForDuplicateBeforeSaving();
      }
    }
  };

  const saveAsNewDraftCalculator = () => {
    if (validateSave()) {
      isDraft.current = true;
      postMethod.current = "POST";
      isSaveAsDraft.current = true;
      dispatch(saveCalcConfigData(true));
    }
  };

  const copyToNewCalculator = () => {
    Swal.fire({
      html:
        "<strong>Are you sure you want to copy this configuration to a new calculator?</strong>",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#808080",
      focusConfirm: false,
      confirmButtonText: "Yes Proceed",
      showClass: {
        popup: "animate__animated animate__fadeInDown"
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp"
      }
    }).then(result => {
      if (result.isConfirmed) {
        setIsEditing(false);
        setIsSaveNewCalc(true);
        dispatch(saveAsNewCalculatorData(true));
      }
    });
  };

  const redirectToDashboard = e => {
    e.preventDefault();
    window.location.href = "/calculatordashboard";
  };

  const deleteOldDraft = () => {
    let mssg =
      "<strong>Are you sure want to Delete '" + calcStatus + "'?</strong>";
    Swal.fire({
      html: mssg,
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#808080",
      focusConfirm: false,
      confirmButtonText: "Yes Proceed",
      showClass: {
        popup: "animate__animated animate__fadeInDown"
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp"
      }
    }).then(result => {
      if (result.isConfirmed) {
        postMethod.current = "DELETE";
        isDraftDelete.current = true;
        calculatorid = calcConfigBasicId.current;
        dispatch(saveCalcConfigData(true));
      }
    });
  };

  const unpublishToPublishCalculator = () => {
    let msg =
      "<strong>Are you sure want to Publish this calculator again?</strong><h6>Once published, this Calculator will be available again for End User Use.</h6>";
    Swal.fire({
      html: msg,
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#808080",
      focusConfirm: false,
      confirmButtonText: "Yes Proceed",
      showClass: {
        popup: "animate__animated animate__fadeInDown"
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp"
      }
    }).then(result => {
      if (result.isConfirmed) {
        isUnpublishToPublish.current = true;
        isDraft.current = false;
        postMethod.current = "PATCH";
        checkForDuplicateBeforeSaving();
      }
    });
  };

  const publishCalculator = () => {
    if (validate()) {
      let mssg =
        "<strong>Are you sure you want to Publish this Calculator?</strong><h6>Once Published, this will be available for End User Use.</h6>";
      Swal.fire({
        html: mssg,
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#808080",
        focusConfirm: false,
        confirmButtonText: "Yes Proceed",
        showClass: {
          popup: "animate__animated animate__fadeInDown"
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp"
        }
      }).then(result => {
        if (result.isConfirmed) {
          isDraft.current = false;
          isPublish.current = true;
          postMethod.current = "POST";
          checkForDuplicateBeforeSaving();
        }
      });
    }
  };

  const unpublishCalculator = () => {
    let mssg =
      "<strong>Are you sure want to Unpublish this Calculator?</strong><h6>Once Unpublished, it will not go back to old, published version of calculator and this Calculator will no longer be available for End User Use.</h6>";
    Swal.fire({
      html: mssg,
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#808080",
      focusConfirm: false,
      confirmButtonText: "Yes Proceed",
      showClass: {
        popup: "animate__animated animate__fadeInDown"
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp"
      }
    }).then(result => {
      if (result.isConfirmed) {
        isUnpublish.current = true;
        postMethod.current = "PATCH";
        dispatch(saveCalcConfigData(true));
      }
    });
  };

  const checkForDuplicateBeforeSaving = () => {
    let data = {
      ...(calculatorid ? { calc_Id: calculatorid } : {}),
      country_id: countryId,
      sector_ids: isEditing ? _.flatMap(sectorIds, "id") : sectorIds,
      req_status:
        isPublish.current || isUnpublishToPublish.current
          ? "PUBLISHED"
          : "DRAFT"
    };
    dispatch(checkDuplicate(data));
  };

  const getAllTabData = () => {
    calcChildRef.current.forEach(element => {
      element.getTabData();
    });
  };

  const exportCalcConfiguration = () => {
    let mainContent = [];
    let basicData = formatBasicData();
    mainContent = mainContent.concat(basicData);

    let segmentHeaders = [
      { field: "---HCP Segments and Multipliers" },
      { field: "HCP Segments and Multipliers" },
      { field: "Band", value: "Country consensus" }
    ];
    mainContent = mainContent.concat(segmentHeaders);
    let segmentsData = formatSegmentsData();
    mainContent = mainContent.concat(segmentsData);

    let statureHeaders = [
      { field: "---HCP KOL Stature and Multipliers" },
      { field: "HCP KOL Stature and Multipliers" },
      { field: "HCP stature", value: "Country consensus" },
      { field: "Select HCP stature" }
    ];
    mainContent = mainContent.concat(statureHeaders);
    let statureData = formatStatureData();
    mainContent = mainContent.concat(statureData);

    let countrySpecLimits = formatCountrySpecData();
    mainContent = mainContent.concat(countrySpecLimits);

    let rulesAndMessageData = formatRulesAndMessageData();
    mainContent = mainContent.concat(rulesAndMessageData);

    let activityLocationContent = formatActivityLocationData();
    let activityTypeContent = formatActivityTypeData();
    let specialtyContent = formatSpecialtyData();

    let data = [
      {
        sheet: "Main",
        columns: [
          { label: "Field", value: "field" },
          { label: "Value", value: "value" }
        ],
        content: mainContent
      },
      {
        sheet: "ActivityLocation",
        columns: [
          { label: "Title", value: "title" },
          { label: "Activity Location", value: "activitylocation" },
          { label: "Hours", value: "hours" },
          { label: "Comments", value: "comments" }
        ],
        content: activityLocationContent
      },
      {
        sheet: "ActivityType",
        columns: [
          { label: "Title", value: "title" },
          { label: "ActivityType", value: "activitytype" },
          { label: "Prep Time", value: "preptime" },
          { label: "Service Time", value: "servicetime" },
          { label: "Comments", value: "comments" },
          { label: "Cap Hours", value: "caphours" },
          { label: "Hrs Rate Cap - Non KOL", value: "ratecap_nonkol" },
          { label: "Hrs Rate Cap - Local KOL", value: "ratecap_local" },
          { label: "Hrs Rate Cap - National KOL", value: "ratecap_national" },
          {
            label: "Hrs Rate Cap - International KOL",
            value: "ratecap_international"
          },
          { label: "Session Cap - Non KOL", value: "sesscap_nonkol" },
          { label: "Session Cap - Local KOL", value: "sesscap_local" },
          { label: "Session Cap - National KOL", value: "sesscap_national" },
          {
            label: "Session Cap - International KOL",
            value: "sesscap_international"
          }
        ],
        content: activityTypeContent
      },
      {
        sheet: "SpecialtyList",
        columns: [
          { label: "Title", value: "title" },
          { label: "Field", value: "field" },
          { label: "Value", value: "value" },
          { label: "Comments", value: "comments" }
        ],
        content: specialtyContent
      }
    ];

    let version = "";
    let versionnum = calcConfiguration?.calcConfigBasic?.version;
    if (versionnum) {
      let versionObj = _.find(calcConfiguration?.versionAndSaveStatus, o => {
        return o.actualVersion === versionnum;
      });
      if (versionObj) {
        version = versionObj.versionToShow.replaceAll(" ", "");
      }
    }
    // export data to excel
    let settings = {
      fileName: calcName.toString() + "_" + version,
      extraLength: 3, // A bigger number means that columns will be wider
      writeOptions: {} // Style options from https://github.com/SheetJS/sheetjs#writing-options
    };
    let xlsx = require("json-as-xlsx");
    xlsx(data, settings);
  };

  const formatBasicData = () => {
    let calculatorID = calcConfiguration?.calcConfig?.item_id;

    let date = new Date(calcConfiguration?.calcConfigBasic?.updated_at);
    let lastUpdated =
      monthsList.current[date.getMonth()] +
      " " +
      date.getDate() +
      ", " +
      date.getFullYear();

    let region = "";
    let objRegion = _.find(regionsMaster?.data, o => {
      return o.id === calcConfiguration?.calcConfig?.region_id;
    });
    if (objRegion) region = objRegion.value;

    let country = "";
    let objCountry = _.find(countryMaster?.data, o => {
      return o.id === calcConfiguration?.calcConfig?.country_id;
    });
    if (objCountry) country = objCountry.country;

    let objSectors = [];
    _.each(calcConfiguration?.calcConfig?.sector_ids, (val, key) => {
      let objSector = _.find(sectorsMaster?.data, o => {
        return o.id === val;
      });
      if (objSector) objSectors.push(objSector.value);
    });
    let sectors = objSectors.join(", ");

    let isKOLApplicable = calcConfiguration?.calcConfigBasic
      ?.is_kol_assessment_applicable
      ? "Yes"
      : "No";
    let isTransApplicable = calcConfiguration?.calcConfigBasic
      ?.is_translation_available
      ? "Yes"
      : "No";

    let defaultLanguage = "";
    let objLanguage = _.find(languageMaster?.data, o => {
      return o.id === calcConfiguration?.calcConfigBasic?.lang_id;
    });
    if (objLanguage) defaultLanguage = objLanguage.value;

    let transLanguages = _.flatMap(
      JSON.parse(
        calcConfiguration?.calcConfigBasic?.available_translated_languages
      ),
      "name"
    ).toString(",");

    let decimalPoniter =
      calcConfiguration?.calcConfigBasic?.punc_decimal_pointer;
    let thousandPointer =
      calcConfiguration?.calcConfigBasic?.punc_thousand_pointer;

    let percentile = "";
    let objPercentile = _.find(percentileMaster?.data, o => {
      return o.id === calcConfiguration?.calcConfigBasic?.percentile;
    });
    if (objPercentile) percentile = objPercentile.value;

    let hideSpecialtyMultiplier = calcConfiguration?.calcConfigBasic
      ?.hide_speciality_multiplier
      ? "Yes"
      : "No";
    let hideStatureMultiplier = calcConfiguration?.calcConfigBasic
      ?.hide_stature_multiplier
      ? "Yes"
      : "No";

    let truevaultLink = calcConfiguration?.calcConfigBasic?.true_vault_link;
    let comments = calcConfiguration?.calcConfigBasic?.comments;

    let currency = "";
    let objCurrency = _.find(currencyMaster?.data, o => {
      return o.id === calcConfiguration?.calcConfigBasic?.currency_id;
    });
    if (objCurrency) currency = objCurrency.value;

    let baseRate = calcConfiguration?.calcConfigBasic?.base_rate;

    let rangesApplicable = JSON.parse(
      calcConfiguration?.calcConfigBasic?.ranges_applicable
    ).toString(",");

    let minRange =
      calcConfiguration?.calcConfigBasic?.min_range_percentage !== null
        ? calcConfiguration?.calcConfigBasic?.min_range_percentage
        : "";
    let maxRange =
      calcConfiguration?.calcConfigBasic?.max_range_percentage !== null
        ? calcConfiguration?.calcConfigBasic?.max_range_percentage
        : "";
    let roundOf =
      calcConfiguration?.calcConfigBasic?.round_of !== null
        ? calcConfiguration?.calcConfigBasic?.round_of
        : "";

    let basicData = [
      { field: "---Main" },
      { field: "Calculator ID", value: calculatorID },
      { field: "Last Update Date", value: lastUpdated },
      { field: "Region", value: region },
      { field: "Market", value: country },
      { field: "Sector", value: sectors },
      { field: "---Country Identifier and Settings" },
      { field: "Country Identifier and Settings" },
      { field: "Is 'KOL Assessment' applicable?", value: isKOLApplicable },
      { field: "Is 'Translation' applicable?", value: isTransApplicable },
      { field: "Default Language", value: defaultLanguage },
      { field: "Translation Languagues", value: transLanguages },
      { field: "Decimal Separator", value: decimalPoniter },
      { field: "Thousand Separator", value: thousandPointer },
      { field: "Percentile", value: percentile },
      { field: "Hide Specialty Multiplier", value: hideSpecialtyMultiplier },
      { field: "Hide Stature Multiplier", value: hideStatureMultiplier },
      { field: "TrueVault Link", value: truevaultLink },
      { field: "Comments", value: comments },
      { field: "---Base Rate" },
      { field: "Base Rate" },
      { field: "Currency", value: currency },
      { field: "Base rate (in local currency)", value: baseRate },
      { field: "---Range Inputs" },
      { field: "Range Inputs" },
      { field: "Ranges Applicable", value: rangesApplicable },
      { field: "Minimum %", value: minRange },
      { field: "Maximum %", value: maxRange },
      { field: "---Rounding" },
      { field: "Rounding" },
      { field: "Round to multiple of", value: roundOf }
    ];
    return basicData;
  };

  const formatSegmentsData = () => {
    let segmentsData = [];

    _.map(segmentsMaster?.data, obj => {
      let segmentItem = _.find(
        calcConfiguration?.calcConfigHCPSegmentMul,
        item => {
          return item.segment_id === obj.id;
        }
      );
      let segment = {
        field: obj.value,
        value: segmentItem ? segmentItem.multiplier : ""
      };
      segmentsData.push(segment);
    });
    return segmentsData;
  };

  const formatStatureData = () => {
    let statureData = [];

    _.map(statureMaster?.data, obj => {
      let statureItem = _.find(
        calcConfiguration?.calcConfigKolStatureMul,
        item => {
          return item.hcp_stature_id === obj.id;
        }
      );
      let stature = {
        field: obj.value,
        value: statureItem ? statureItem.multiplier : ""
      };
      statureData.push(stature);
    });
    return statureData;
  };

  const formatCountrySpecData = () => {
    let ratePerHrLimit =
      calcConfiguration?.calcCountrySpecificLimit?.rate_per_hr_limit !== null
        ? calcConfiguration?.calcCountrySpecificLimit?.rate_per_hr_limit
        : "";

    let hrLimitGen =
      calcConfiguration?.calcCountrySpecificLimit?.hour_limit !== null
        ? calcConfiguration?.calcCountrySpecificLimit?.hour_limit
        : "";

    let sessionLimitGen =
      calcConfiguration?.calcCountrySpecificLimit?.session_limit_general !==
      null
        ? calcConfiguration?.calcCountrySpecificLimit?.session_limit_general
        : "";

    let travelDiscount =
      calcConfiguration?.calcConfigTravelTimeDiscount?.percentage_discount !==
      null
        ? calcConfiguration?.calcConfigTravelTimeDiscount?.percentage_discount
        : "";

    let specialtyGroup = "";
    let objSpecialtyGroup = _.find(specialtyGroupMaster?.data, o => {
      return o.id === calcConfiguration?.calcConfigBasic?.hcp_speciality_group;
    });
    if (objSpecialtyGroup) specialtyGroup = objSpecialtyGroup.value;

    let countrySpecLimits = [
      { field: "---Country Specific Limits" },
      { field: "Country Specific Limits" },
      { field: "Rate/Hourly Limit - General", value: ratePerHrLimit },
      { field: "Hourly Limit - General", value: hrLimitGen },
      { field: "Session Limit - General", value: sessionLimitGen },
      { field: "---Travel Time Discount" },
      { field: "Travel Time Discount (% of service per hour rate)" },
      { field: "% of the hourly rate", value: travelDiscount },
      { field: "---HCP Specialty Group" },
      { field: "Specialty Group", value: specialtyGroup }
    ];
    return countrySpecLimits;
  };

  const formatRulesAndMessageData = () => {
    let groundRules = [];
    _.map(
      _.orderBy(calcConfiguration?.calcConfigGroundRules, "order", "asc"),
      o => {
        let rule = { field: o.rule_desc, value: o.order };
        groundRules.push(rule);
      }
    );

    let rulesAndMessageData = [
      { field: "---Company Ground Rules" },
      { field: "Company Ground Rules" }
    ];
    rulesAndMessageData = rulesAndMessageData.concat(groundRules);

    let internalMssg = "";
    let mssgObj = _.find(calcConfiguration?.calcConfigMessages, o => {
      return o.message_type.toLowerCase() === "internal message";
    });
    if (mssgObj) internalMssg = convertToPlain(mssgObj.messages);

    let internalMssgData = [
      { field: "---Internal Message" },
      { field: "Message", value: internalMssg }
    ];
    rulesAndMessageData = rulesAndMessageData.concat(internalMssgData);

    return rulesAndMessageData;
  };

  const convertToPlain = html => {
    // Create a new div element
    var tempDivElement = document.createElement("div");
    // Set the HTML content with the given value
    tempDivElement.innerHTML = html;
    // Retrieve the text property of the element
    return tempDivElement.textContent || tempDivElement.innerText || "";
  };

  const formatActivityLocationData = () => {
    let actLocationData = [];

    _.map(travelDistanceMaster?.data, obj => {
      let travelItem = _.find(calcConfiguration?.calcConfigTravelTime, item => {
        return item.travel_dist_id === obj.id;
      });
      let travel = {
        title: "Activity Location",
        activitylocation: obj.title,
        hours: travelItem ? travelItem.time_hr : "",
        comments: travelItem ? travelItem.comments : ""
      };
      actLocationData.push(travel);
    });
    return actLocationData;
  };

  const formatActivityTypeData = () => {
    //find stature IDs
    let nonKOL = _.find(statureMaster?.data, o => {
      return o.value.toUpperCase() === "NON KOL";
    })
      ? _.find(statureMaster?.data, o => {
          return o.value.toUpperCase() === "NON KOL";
        }).id
      : "";

    let localKOL = _.find(statureMaster?.data, o => {
      return o.value.toUpperCase() === "LOCAL KOL";
    })
      ? _.find(statureMaster?.data, o => {
          return o.value.toUpperCase() === "LOCAL KOL";
        }).id
      : "";

    let nationalKOL = _.find(statureMaster?.data, o => {
      return o.value.toUpperCase() === "NATIONAL KOL";
    })
      ? _.find(statureMaster?.data, o => {
          return o.value.toUpperCase() === "NATIONAL KOL";
        }).id
      : "";

    let internationalKOL = _.find(statureMaster?.data, o => {
      return o.value.toUpperCase() === "INTERNATIONAL KOL";
    })
      ? _.find(statureMaster?.data, o => {
          return o.value.toUpperCase() === "INTERNATIONAL KOL";
        }).id
      : "";

    let actTypeData = activityTypeMaster?.data;

    _.map(actTypeData, o => {
      let matchItem = _.find(
        calcConfiguration?.calcConfigActivityTypesHrsAndHrCap,
        item => {
          return item.activity_type_id === o.id;
        }
      );

      let nonKOLRateCap = "",
        localKOLRateCap = "",
        nationalKOLRateCap = "",
        internationalKOLRateCap = "";
      let nonKOLSessCap = "",
        localKOLSessCap = "",
        nationalKOLSessCap = "",
        internationalKOLSessCap = "";

      if (matchItem) {
        //find rate caps
        let nonKOLItem = _.find(
          matchItem.calcConfigActivityTypeHcpStatureCap,
          obj => {
            return obj.hcp_stature_id === nonKOL;
          }
        );
        nonKOLRateCap = nonKOLItem ? nonKOLItem.rate_cap : "";

        let localKOLItem = _.find(
          matchItem.calcConfigActivityTypeHcpStatureCap,
          obj => {
            return obj.hcp_stature_id === localKOL;
          }
        );
        localKOLRateCap = localKOLItem ? localKOLItem.rate_cap : "";

        let nationalKOLItem = _.find(
          matchItem.calcConfigActivityTypeHcpStatureCap,
          obj => {
            return obj.hcp_stature_id === nationalKOL;
          }
        );
        nationalKOLRateCap = nationalKOLItem ? nationalKOLItem.rate_cap : "";

        let internationalKOLItem = _.find(
          matchItem.calcConfigActivityTypeHcpStatureCap,
          obj => {
            return obj.hcp_stature_id === internationalKOL;
          }
        );
        internationalKOLRateCap = internationalKOLItem
          ? internationalKOLItem.rate_cap
          : "";

        //find session caps
        let nonKOLSess = _.find(
          matchItem.calcConfigActivityTypeHcpStatureSessionCap,
          obj => {
            return obj.hcp_stature_id === nonKOL;
          }
        );
        nonKOLSessCap = nonKOLSess ? nonKOLSess.session_cap : "";

        let localKOLSess = _.find(
          matchItem.calcConfigActivityTypeHcpStatureSessionCap,
          obj => {
            return obj.hcp_stature_id === localKOL;
          }
        );
        localKOLSessCap = localKOLSess ? localKOLSess.session_cap : "";

        let nationalKOLSess = _.find(
          matchItem.calcConfigActivityTypeHcpStatureSessionCap,
          obj => {
            return obj.hcp_stature_id === nationalKOL;
          }
        );
        nationalKOLSessCap = nationalKOLSess ? nationalKOLSess.session_cap : "";

        let internationalKOLSess = _.find(
          matchItem.calcConfigActivityTypeHcpStatureSessionCap,
          obj => {
            return obj.hcp_stature_id === internationalKOL;
          }
        );
        internationalKOLSessCap = internationalKOLSess
          ? internationalKOLSess.session_cap
          : "";
      }

      o.title = "Activity Type";
      o.activitytype = o.value;
      o.preptime = matchItem ? matchItem.prep_time_hrs : "";
      o.servicetime = matchItem ? matchItem.service_time_hrs : "";
      o.comments = matchItem ? matchItem.comments : "";
      o.caphours = matchItem ? matchItem.hr_cap : "";
      o.ratecap_nonkol = nonKOLRateCap;
      o.ratecap_local = localKOLRateCap;
      o.ratecap_national = nationalKOLRateCap;
      o.ratecap_international = internationalKOLRateCap;
      o.sesscap_nonkol = nonKOLSessCap;
      o.sesscap_local = localKOLSessCap;
      o.sesscap_national = nationalKOLSessCap;
      o.sesscap_international = internationalKOLSessCap;
    });
    return actTypeData;
  };

  const formatSpecialtyData = () => {
    let allSpecialties = [];

    let specialtyGroup =
      calcConfiguration?.calcConfigBasic?.hcp_speciality_group;
    if (specialtyGroup && specialtyGroup !== null) {
      allSpecialties = _.filter(specialtyMaster?.data, row => {
        return (
          _.find(row.mapping, obj => {
            return obj.id === specialtyGroup;
          }) !== undefined
        );
      });

      allSpecialties = _.map(allSpecialties, o => {
        let obj = {
          title: "HCP Specialty List",
          id: o.hcp_speciality_id,
          field: o.hcp_speciality,
          value: "",
          comments: ""
        };
        return obj;
      });

      _.map(allSpecialties, o => {
        let matchItem = _.find(
          calcConfiguration?.calcConfigHcpSpeciality,
          item => {
            return item.hcp_speciality_id === o.id;
          }
        );

        let segment = "";
        if (matchItem) {
          segment = _.find(segmentsMaster?.data, segItem => {
            return segItem.id === matchItem.segment_id;
          });
        }
        o.value = segment ? segment.value : "";
        o.comments = matchItem ? matchItem.comments : "";
      });
    }
    return allSpecialties;
  };

  const calculatorHistory = () => {
    redirectUrl.current = "/calculatorhistory/" + calculatorid;
    window.location.href = redirectUrl.current;
  };

  const previewCalculator = () => {
    if (validate()) {
      redirectUrl.current =
        selectedVersion && selectedVersion !== ""
          ? "/fmvcalculator/preview/" + calculatorid + "/" + selectedVersion
          : "/fmvcalculator/preview/" + calculatorid;
      // window.location.href = redirectUrl.current;
      window.open(redirectUrl.current);
    } else {
      Swal.fire(
        "",
        "<h6 style='color: red'>Missing one or more required field(s) information to Preview the Calculator.</h6><h6 style='color: red'>Please check the required field validations to proceed.</h6>",
        "warning"
      );
    }
  };

  const onSelectAdminTool = value => {
    if (value && value !== null && value !== "") {
      if (value.toLowerCase() === "calculator_history") {
        calculatorHistory();
      } else if (value.toLowerCase() === "preview_calculator") {
        previewCalculator();
      } else if (value.toLowerCase() === "export_configuration") {
        exportCalcConfiguration();
      } else if (value.toLowerCase() === "copy_to_new") {
        copyToNewCalculator();
      }
    }
  };

  return (
    <>
      {accessUrl.current !== "" &&
        (!isAccess ? <Redirect to={accessUrl.current} /> : "")}

      {isSaveNewCalc ? <Redirect to="/calculatorconfig" /> : ""}

      <div className="calculator-config">
        <ShowLoader></ShowLoader>

        {isRedirect ? <Redirect to={redirectUrl.current} /> : ""}
        <div>
          <Container className="calc-config-static">
            <Row>
              <Col md={5}>
                <h4>Calculator Configuration</h4>
              </Col>
              <Col />
              <Col md={6} className="updated-date">
                {calcStatus ? (
                  <>
                    <h6>
                      Last Updated: {updatedOn.current} - Updated By:{" "}
                      {updatedBy.current}
                    </h6>
                    <label>Calculator ID: {calculatorIdValue}</label>
                  </>
                ) : (
                  ""
                )}
                {/* {isEditing && showCalcHistory ?
                  <div
                    className="calc_config_history_link"
                    onClick={calculatorHistory}
                  >
                    Calculator History
                  </div>
                  : ""} */}
              </Col>
            </Row>
            <Row>
              <Col className="label-name" md={1}>
                <span>Name</span>
                <span className="asterisk-mandatory">*</span>
              </Col>
              <Col md={4}>
                <input
                  className={showNameBorder ? "cal-val-red" : undefined}
                  type="text"
                  value={calcName}
                  onChange={e => setCalcName(e.target.value)}
                  id="calc-config-name-id"
                />
              </Col>
              <Col className="label-name" md={1}>
                <span>Region</span>
                <span className="asterisk-mandatory">*</span>
              </Col>
              <Col md={3}>
                <DropDownComp
                  show={showRegionBorder}
                  listObj={regionsListObj.current}
                  callOnChange={setRegionId}
                  objId={regionId}
                  disabled={isEditing || isRegionId.current}
                  holderName={"Select"}
                  selecteId={"calc-config-region-id"}
                ></DropDownComp>
              </Col>
              <Col md={3}>
                <div
                  className={
                    calcStatus.toUpperCase().includes("PUBLISHED") &&
                    !calcStatus.toUpperCase().includes("UNPUBLISHED")
                      ? "calc-status-published calcStatusForDesktop"
                      : "calc-status calcStatusForDesktop"
                  }
                >
                  {calcStatus !== undefined && calcStatus !== "" ? (
                    <label>STATUS: {calcStatus}</label>
                  ) : (
                    calcStatus
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="label-name" md={1}>
                <span>Sector</span>
                <span className="asterisk-mandatory">*</span>
              </Col>
              <Col
                md={4}
                className={showSectorBorder ? "multiselectborder" : undefined}
              >
                {isEditing ? (
                  <Multiselect
                    options={sectorsListObj.current}
                    selectedValues={sectorIds}
                    onSelect={sectorOnSelect}
                    onRemove={sectorOnRemove}
                    displayValue="name"
                    disable={isEditing}
                    id="calc-config-sector-multis-id"
                  />
                ) : (
                  <span>
                    <Multiselect
                      options={sectorsListObj.current}
                      onSelect={sectorOnSelect}
                      onRemove={sectorOnRemove}
                      displayValue="name"
                      disable={isEditing}
                      id="calc-config-sector-multis-id"
                      avoidHighlightFirstOption={true}
                    />
                  </span>
                )}
              </Col>
              <Col className="label-name" md={1}>
                <span>Market</span>
                <span className="asterisk-mandatory">*</span>
              </Col>
              <Col md={3}>
                <DropDownComp
                  show={showCountryBorder}
                  listObj={countrieslist}
                  callOnChange={setCountryId}
                  objId={countryId}
                  disabled={isEditing}
                  holderName={"Select"}
                  selecteId={"calc-config-country-id"}
                ></DropDownComp>
              </Col>
              <Col className="label-name" md={3}>
                {isEditing ? (
                  <div>
                    <div
                      className={
                        calcStatus.toUpperCase().includes("PUBLISHED") &&
                        !calcStatus.toUpperCase().includes("UNPUBLISHED")
                          ? "calc-status-published calcStatusForMobile"
                          : "calc-status calcStatusForMobile"
                      }
                    >
                      {calcStatus !== undefined && calcStatus !== "" ? (
                        <label>STATUS: {calcStatus}</label>
                      ) : (
                        calcStatus
                      )}
                    </div>
                    <div className="switch-version">
                      <DropDownComp
                        listObj={allVersionsList.current}
                        callOnChange={onVersionChange}
                        objId={selectedVersion}
                        disabled={false}
                        holderName={"Switch to Version"}
                        selecteId={"calc-config-switch-version-id"}
                      ></DropDownComp>
                    </div>
                    {/* <div className="switch-version calc-config-buttons">
                      <Button
                        variant="success"
                        className="export-config"
                        onClick={exportCalcConfiguration}
                      >
                        Export Configuration
                      </Button>
                    </div>
                    {isEditing && isLastVersion.current ? (
                      <div
                        className="calc_config_history_link"
                        onClick={previewCalculator}
                      >
                        Preview Calculator
                      </div>
                    ) : (
                      ""
                    )} */}
                    <div className="switch-version">
                      <select
                        className="select-config margintop-lg"
                        value={adminToolSelected}
                        onChange={e => onSelectAdminTool(e.target.value)}
                      >
                        <option value="" hidden>
                          Admin Tools
                        </option>
                        {isEditing && showCalcHistory ? (
                          <option value="calculator_history">
                            Calculator History
                          </option>
                        ) : (
                          ""
                        )}
                        {isEditing && isLastVersion.current ? (
                          <option value="preview_calculator">
                            Preview Calculator
                          </option>
                        ) : (
                          ""
                        )}
                        {isEditing ? (
                          <option value="export_configuration">
                            Export Configuration
                          </option>
                        ) : (
                          ""
                        )}
                        {isEditing && !isHCCOorAuditor.current ? (
                          <option value="copy_to_new">Copy to New</option>
                        ) : (
                          ""
                        )}
                      </select>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </Container>
          <Container>
            <Col className="calc-validation-col">
              {validationMessage?.map((o, i) => {
                return (
                  <Row className="calc-validation-msg" key={i}>
                    {o.msg}
                  </Row>
                );
              })}
            </Col>
          </Container>
          <Container>
            <Row>
              <Col>
                <Tabs
                  defaultActiveKey="basic"
                  id="controlled-tab-example"
                  onSelect={() => getAllTabData()}
                >
                  <Tab eventKey="basic" title="General Configuration">
                    <CalculatorConfigBasic tabChangeHandler={calcChildRef} />
                  </Tab>
                  <Tab eventKey="hcpSegments" title="HCP Segment Multipliers">
                    <HCPSegment tabChangeHandler={calcChildRef} />
                  </Tab>
                  <Tab eventKey="hcpStature" title="HCP Stature Multipliers">
                    <HCPStature tabChangeHandler={calcChildRef} />
                  </Tab>
                  <Tab
                    eventKey="countrySpecificLimit"
                    title="Market Specific Limits"
                  >
                    <CountrySpecificLimit tabChangeHandler={calcChildRef} />
                  </Tab>
                  <Tab eventKey="activityType" title="Activity Type and Caps">
                    <ActivityType tabChangeHandler={calcChildRef} />
                  </Tab>
                  <Tab
                    eventKey="activityLocation"
                    title="Activity Location - Travel Time"
                  >
                    <ActivityLocation tabChangeHandler={calcChildRef} />
                  </Tab>
                  <Tab eventKey="groundRules" title="Ground Rules">
                    <GroundRules tabChangeHandler={calcChildRef} />
                  </Tab>
                  <Tab eventKey="hcpSpecialty" title="HCP Specialty">
                    <HCPSpecialty tabChangeHandler={calcChildRef} />
                  </Tab>
                </Tabs>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="calc-config-buttons">
          {calcStatus.toUpperCase() !== "" || isHCCOorAuditor.current ? (
            <Button
              variant="secondary"
              className="btnback"
              onClick={redirectToDashboard}
            >
              Back
            </Button>
          ) : (
            <Button variant="outline-danger" onClick={redirectToDashboard}>
              Cancel
            </Button>
          )}
          {calcStatus.toUpperCase() === "" && !isHCCOorAuditor.current ? (
            <Button
              variant={!isEditing ? "danger" : undefined}
              onClick={saveCalculator}
            >
              Save
            </Button>
          ) : (
            ""
          )}
          {/* {isEditing && !isHCCOorAuditor.current ? (
            <Button variant="success" onClick={copyToNewCalculator}>
              Copy To New
            </Button>
          ) : (
            ""
          )} */}
          {calcStatus.toUpperCase().includes("DRAFT") &&
          isLastVersion.current &&
          isEditing &&
          !isHCCOorAuditor.current ? (
            <Button
              variant={!isEditing && "danger"}
              className={isEditing ? "calcconfig-update-btn" : undefined}
              onClick={saveCalculator}
            >
              Update
            </Button>
          ) : (
            ""
          )}
          {isEditing && isLastVersion.current && !isHCCOorAuditor.current ? (
            <Button variant="success" onClick={saveAsNewDraftCalculator}>
              Save As New Draft
            </Button>
          ) : (
            ""
          )}
          {((isLastVersion.current &&
            !calcStatus.toUpperCase().includes("PUBLISHED")) ||
            calcStatus.toUpperCase() === "") &&
          !isHCCOorAuditor.current ? (
            <Button
              variant=""
              className="calcconfig-publish-btn"
              onClick={publishCalculator}
            >
              Publish
            </Button>
          ) : (
            ""
          )}
          {isLastUnpublishedVersion.current && !isHCCOorAuditor.current ? (
            <Button
              variant=""
              className="calcconfig-publish-btn"
              onClick={unpublishToPublishCalculator}
            >
              Publish Again
            </Button>
          ) : (
            ""
          )}
          {calcStatus.toUpperCase().includes("DRAFT") &&
          !isLastVersion.current &&
          !isDraftDeleted.current &&
          isEditing &&
          !isHCCOorAuditor.current ? (
            <Button
              variant=""
              className="calcconfig-publish-btn"
              onClick={deleteOldDraft}
            >
              Delete
            </Button>
          ) : (
            ""
          )}
          {isLastPublishedVersion.current &&
          endUserStatus === "PUBLISHED" &&
          !isHCCOorAuditor.current ? (
            <Button
              variant=""
              className="calcconfig-publish-btn"
              onClick={unpublishCalculator}
            >
              Unpublish
            </Button>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}
