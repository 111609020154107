import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ReactTabulator } from "react-tabulator";
import DOMPurify from "dompurify";
import { Row, Col, Popover, OverlayTrigger } from "react-bootstrap";
import add from "../assets/add.svg";
import { Image, Modal, Button } from "react-bootstrap";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
// import CustomUploadAdapterPlugin from "../helper/customUploadEditorPlugin";
// import ClassicEditor from '../lib/ckeditor5/ckeditor'
// import '../lib/ckeditor5/ckstyles.css'
import Swal from "sweetalert2";
import deleteicon from "../assets/delete-icon.png";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  setGroundRulesMessagesData,
  fetchPagesMessages,
  fetchPageConfigMessagesData,
  setIsDataProcessedForSaveAsNew
} from "../actions/calcConfig";
import _ from "lodash";
import { Calc_Tabs } from "../helper/constants";
import { TextEditor } from "../helper/textEditor";

export function GroundRules({ tabChangeHandler }) {
  const dispatch = useDispatch();

  let { calculatorid } = useParams();
  const [isEditing, setIsEditing] = useState(false);
  const [initialize, setInitialize] = useState(false);
  const [dataInitialize, setDataInitialize] = useState(false);
  const [pageid, setPageId] = useState("");
  const [groundRulesData, setGroundRulesData] = useState([]);
  const [groundRulesTableColumns, setGroundRulesTableColumns] = useState([]);
  const [groundRulesTabledata, setGroundRulesTableData] = useState(null);
  const [messagesTableColumns, setMessagesTableColumns] = useState([]);
  const [messagestabledata, setMessagesTableData] = useState(null);
  const [initialData, setInitialData] = useState([]);
  const [updatedData, setUpdatedData] = useState([]);
  const [show, setShow] = useState(false);
  // const [itemid, setItemId] = useState("");
  const [contentType, setContentType] = useState("");
  const [editorData, setEditorData] = useState("");
  // const [content, setContent] = useState("");
  const [isMessageData, setIsMessageData] = useState(false);
  const [isContent, setIsContent] = useState(false);
  const [rowSelected, setRowSelected] = useState(false);

  const pageConfig = useSelector(state => state.calcConfig);
  // const isDetailLoaded = useSelector(
  //   state => state.calcConfig.isMessageDetailLoaded
  // );
  const calcConfigMessages = useSelector(
    state => state.calcConfig?.calcConfigData?.data?.calcConfigMessages
  );
  const calcConfigGroundRules = useSelector(
    state => state.calcConfig?.calcConfigData?.data?.calcConfigGroundRules
  );
  const saveAsNew = useSelector(state => state.calcConfig.saveAsNew);

  const tableRef = useRef(null);
  const ref = useRef(null);

  useEffect(() => {
    if (calculatorid !== undefined) {
      setIsEditing(true);
    }
  }, [calculatorid]);

  useEffect(() => {
    if (!initialize) {
      dispatch(fetchPagesMessages());
    }
    setInitialize(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialize]);

  useEffect(() => {
    if (pageConfig !== undefined && !isMessageData) {
      let selectedData = _.filter(pageConfig?.messagePages?.data, o => {
        return o.page_name === "Calculator configuration";
      });
      if (selectedData !== undefined && selectedData.length > 0) {
        setPageId(selectedData[0]?.id);
      }

      if (
        pageConfig?.pageMessageData?.data !== undefined &&
        pageConfig?.pageMessageData?.data?.length > 0
      ) {
        let filterData = _.filter(pageConfig?.pageMessageData?.data, o => {
          return o.content_type === "Internal Message";
        });
        let data = _.map(filterData, o => {
          return {
            message_type: o.content_type,
            messages: o.contents
          };
        });
        setInitialData(filterData);
        if (!isContent) {
          setMessagesTableData(data);
          setIsContent(true);
        }
        setContentType(filterData[0].content_type);
        setEditorData(filterData[0].contents);
        // setContent(filterData[0].contents);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageConfig, isMessageData]);

  useEffect(() => {
    if (pageid !== undefined && pageid !== "") {
      dispatch(fetchPageConfigMessagesData(pageid));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageid]);

  useEffect(() => {
    let data = [];
    if (
      updatedData !== undefined &&
      updatedData !== "" &&
      updatedData.length > 0 &&
      !dataInitialize
    ) {
      data = _.map(updatedData, o => {
        return {
          message_type: o.content_type,
          messages: o.contents,
          item_id: o.item_id
        };
      });
      setMessagesTableData(data);
      setInitialData(updatedData);
      setContentType(updatedData[0].content_type);
      setEditorData(updatedData[0].contents);
      // setContent(updatedData[0].contents);
      setDataInitialize(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedData, pageConfig]);

  useEffect(() => {
    let messagesTableColumns = [
      {
        title: "Content Type",
        field: "content_type",
        width: 200,
        tooltip: true
      },
      {
        title: "Content",
        field: "contents",
        formatter: function(cell, formatterParams, onRendered) {
          return DOMPurify.sanitize(cell.getValue());
        }
      },
      {
        // title:  " ",
        hozAlign: "center",
        width: 45,
        headerSort: false,
        formatter: editIcon,
        cellClick: editItem
      }
    ];
    let groundRulesTableColumns = [
      {
        formatter: "rowSelection",
        titleFormatter: "rowSelection",
        hozAlign: "center",
        headerSort: false,
        width: 10,
        cellClick: function(e, cell) {
          cell.getRow().toggleSelect();
        }
      },
      {
        title: "Rule",
        field: "rule_desc",
        editor: "input",
        tooltip: true
      },
      {
        title: "Order",
        field: "order",
        editor: "number",
        editorParams: { min: 0 },
        tooltip: true,
        width: 100
      }
    ];
    setMessagesTableColumns(messagesTableColumns);
    setGroundRulesTableColumns(groundRulesTableColumns);
  }, []);

  const editIcon = function() {
    return (
      "<img height='20px' width='20px' src='" +
      process.env.PUBLIC_URL +
      "/edit-icon.png'/>"
    );
  };

  const editItem = function(e, cell) {
    // setItemId(cell.getRow().getData().id);
    setShow(true);
  };

  useEffect(() => {
    if (calcConfigMessages !== undefined && calcConfigMessages.length > 0) {
      let filterData = _.filter(calcConfigMessages, o => {
        return o.message_type === "Internal Message";
      });
      let data = _.map(filterData, o => {
        return {
          message_type: o.message_type,
          messages: o.messages,
          item_id: o.item_id
        };
      });
      let updateTableData = _.map(filterData, o => {
        return {
          content_type: o?.message_type,
          contents: o?.messages,
          created_at: o?.created_at,
          created_by: o?.created_by,
          deleted_by: o?.deleted_by,
          item_id: o?.item_id,
          status: o.status,
          updated_at: o?.updated_at,
          updated_by: o?.updated_by
        };
      });
      setInitialData(updateTableData);
      setMessagesTableData(data);
      setContentType(filterData[0]?.message_type);
      setEditorData(filterData[0]?.messages);
      // setContent(filterData[0]?.messages);
      setIsMessageData(true);
    }
  }, [calcConfigMessages]);

  useEffect(() => {
    if (calcConfigGroundRules !== undefined && isEditing) {
      setGroundRulesData(calcConfigGroundRules);
      setGroundRulesTableData(
        _.map(calcConfigGroundRules, o => {
          return {
            rule_desc: o?.rule_desc,
            item_id: o?.item_id,
            order: o?.order
          };
        })
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calcConfigGroundRules]);

  useEffect(() => {
    if (saveAsNew) {
      let groundRules = _.forEach(groundRulesTabledata, obj => {
        delete obj["item_id"];
      });
      let messages = _.forEach(messagestabledata, obj => {
        delete obj["item_id"];
      });
      dispatch(
        setGroundRulesMessagesData({
          groundRulesTabledata: groundRules,
          messagestabledata: messages
        })
      );
      dispatch(
        setIsDataProcessedForSaveAsNew({
          groundRulesAndMessagesProcessed: true
        })
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveAsNew]);

  useEffect(() => {
    tabChangeHandler.current[Calc_Tabs.TAB_GroundRules] = {
      getTabData: getTabData
    };
    getTabData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groundRulesTabledata, messagestabledata]);

  const getTabData = function() {
    dispatch(
      setGroundRulesMessagesData({
        groundRulesTabledata: groundRulesTabledata,
        messagestabledata: messagestabledata
      })
    );
  };

  function onCellEdited(cell) {
    if (
      cell
        .getRow()
        .getTable()
        .getData() !== undefined
    ) {
      //   let selectedData = _.filter(
      //     cell.getRow().getTable().getData(),
      //     (o) => {
      //       return o.rule_desc !== undefined && o.rule_desc.trim() !== "";
      //     },
      //     0
      //   );
      let selectedData = cell
        .getRow()
        .getTable()
        .getData();
      if (selectedData !== undefined && selectedData.length > 0) {
        setGroundRulesTableData(
          _.map(selectedData, o => {
            return {
              rule_desc: o?.rule_desc,
              item_id: o?.item_id,
              order: o?.order
            };
          })
        );
      }
    }
  }

  function addNewRow() {
    tableRef.current.table.addRow({}, true);
  }

  const handleClose = () => {
    setShow(false);
    setEditorData(initialData[0]?.contents);
  };

  const onModalSave = function() {
    if (contentType.trim() === "" && editorData.trim() === "") {
      Swal.fire({
        title: "Cannot save blank data!",
        icon: "warning"
      });
      return;
    }

    let updatedData = _.map(initialData, o => {
      return {
        content_type: o?.content_type,
        contents: editorData,
        created_at: o?.created_at,
        created_by: o?.created_by,
        deleted_by: o?.deleted_by,
        item_id: o.item_id,
        status: o?.status,
        updated_at: o?.updated_at,
        updated_by: o?.updated_by
      };
    });
    setDataInitialize(false);
    setUpdatedData(updatedData);

    setShow(false);
  };

  const deleteItem = () => {
    let rowsToDelete = tableRef.current?.table?.getSelectedRows();
    _.forEach(rowsToDelete, o => {
      o.delete();
    });
    if (tableRef?.current?.table.getData() !== undefined) {
      let selectedData = tableRef?.current?.table.getData();
      if (selectedData !== undefined && selectedData.length > 0) {
        setGroundRulesTableData(
          _.map(selectedData, o => {
            return {
              rule_desc: o?.rule_desc,
              item_id: o?.item_id,
              order: o?.order
            };
          })
        );
      } else if (selectedData !== undefined && selectedData.length === 0) {
        setGroundRulesTableData(selectedData);
      }
    }
    setRowSelected(false);
    // showtoast.showSuccess("Rule deleted successfully!");
  };

  const messagepopover = (
    <Popover id="popover-basic">
      <Popover.Body>
        To exclude Internal Message please leave the Content blank.
      </Popover.Body>
    </Popover>
  );

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        To delete any row please select row and click on delete button.
      </Popover.Body>
    </Popover>
  );

  return (
    <div className="ground-rules">
      <div>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          toastStyle={{
            border: "1px solid",
            boxShadow: "10px 10px 16px 0px rgba(0,0,0,0.75)",
            color: "black",
            backgroundColor: "#e6e6e6",
            fontWeight: "bold"
          }}
        />
      </div>
      <Row>
        <Col className="section-header" md={12}>
          Message Configuration
          <OverlayTrigger
            trigger="click"
            placement="right"
            rootClose={true}
            overlay={messagepopover}
          >
            <Button className="Info-btn-tbulator">i</Button>
          </OverlayTrigger>
        </Col>
      </Row>
      {initialData !== undefined ? (
        <ReactTabulator
          ref={a => (ref.current = a)}
          columns={messagesTableColumns}
          data={initialData}
          key={"messageConfig"}
          height="100%"
          layout="fitColumns"
        />
      ) : (
        ""
      )}
      <Row>
        <Col className="section-header" md={12}>
          Ground Rules
          <OverlayTrigger
            trigger="click"
            placement="right"
            rootClose={true}
            overlay={popover}
          >
            <Button className="Info-btn-tbulator">i</Button>
          </OverlayTrigger>
        </Col>
      </Row>
      <div className="rule-table-heading">
        <span>Add Ground Rules</span> &nbsp;&nbsp;
        <div>
          <Image
            src={add}
            className="add-item"
            alt="Add New Item"
            onClick={() => addNewRow()}
          ></Image>
        </div>
        {rowSelected ? (
          <div className="delete-ground-rule">
            <button onClick={() => deleteItem()}>
              <Image src={deleteicon} alt="Delete"></Image>
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
      <ReactTabulator
        ref={a => (tableRef.current = a)}
        columns={groundRulesTableColumns}
        data={groundRulesData}
        key={"groundRules"}
        cellEdited={onCellEdited}
        height="100%"
        layout="fitColumns"
        rowSelectionChanged={function(data, rows) {
          if (rows.length > 0) setRowSelected(true);
          else setRowSelected(false);
        }}
      />

      <Modal
        size="xl"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Content</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="content-type pc-content-box">
            <label className="pc-content-label">Content Type</label>&nbsp;&nbsp;
            <input
              type="text"
              value={contentType}
              className="pc-content-type"
              disabled={true}
            />
          </div>
          <div id="toolbar-container"></div>
          {/* <CKEditor
            editor={DecoupledEditor}
            data={editorData}
            onReady={(editor) => {
              // The toolbar needs to be explicitly appended
              document
                .querySelector("#toolbar-container")
                .appendChild(editor.ui.view.toolbar.element);
              window.editor = editor;
            }}
            onChange={(event, editor) => setEditorData(editor.getData())}
          /> */}

          <TextEditor
            setContents={editorData}
            onChange={value => setEditorData(value)}
          />
          {/* <textarea value={editorData} className='editor'></textarea> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={onModalSave}>
            Save
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
