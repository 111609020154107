import { createBrowserHistory } from "history";
import { applyMiddleware, compose, createStore } from "redux";
import { routerMiddleware } from "connected-react-router";
import rootReducer from "../reducers";
import apiMiddleware from "../middleware/apiMiddleware";

export const history = createBrowserHistory();

function configureStore(preloadedState) {
  const store = createStore(
    rootReducer(history), // root reducer with router state
    preloadedState,
    compose(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
        apiMiddleware
        // ... other middlewares ...
      )
    )
  );
  return store;
}

export default configureStore;
