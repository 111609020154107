import loadingSpinner from "../assets/loading-spinner.gif";
import { Image } from "react-bootstrap";
import { useSelector } from "react-redux";

export function ShowLoader() {
  const isLoadingMaster = useSelector(
    state => state.masterDataConfig.isLoadingData
  );
  const isLoadingConfigData = useSelector(
    state => state.calcConfig.isLoadingData
  );
  const isLoadingConfiguration = useSelector(
    state => state.calcConfig.isLoadingConfiguration
  );
  const isUpdating = useSelector(state => state.calcConfig.isUpdatingData);
  const isChecking = useSelector(state => state.calcConfig.isChecking);
  const isLoadingCalculator = useSelector(
    state => state.calculator.isLoadingData
  );
  const isLoadingKOL = useSelector(
    state => state.kolAssessment.isLoadingKolData
  );
  const isUpdatingKolData = useSelector(
    state => state.kolAssessment.isUpdatingKolData
  );
  const isDeletingKolData = useSelector(
    state => state.kolAssessment.isDeletingKolData
  );
  const isLoadingHistory = useSelector(
    state => state.calculatorHistory.isLoadingHistory
  );
  const saveAsNew = useSelector(state => state.calcConfig.saveAsNew);
  const isExecuting = useSelector(state => state.calcConfig.isExecuting);

  return (
    <div>
      {isLoadingMaster ||
      isLoadingConfigData ||
      isLoadingConfiguration ||
      isUpdating ||
      isChecking ||
      isLoadingCalculator ||
      isLoadingKOL ||
      isUpdatingKolData ||
      isDeletingKolData ||
      isLoadingHistory ||
      saveAsNew ||
      isExecuting ? (
        <div className="loading-spinner">
          <Image src={loadingSpinner} alt="Loading..."></Image>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
