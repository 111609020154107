import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import jnjlogo from "../assets/jnj-logo.png";
import { setIsExecutingAction } from "../actions/calcConfig";

export function ExportToPDF(
  elementID,
  exportFileName,
  userID,
  headerElement,
  headerText,
  dispatch,
  draftStatus
) {
  var input = document.getElementById(elementID);

  Promise.all([
    new Promise((success, fail) => {
      if (headerElement) {
        html2canvas(headerElement).then(canvas => {
          success(canvas);
        });
      } else {
        success(null);
      }
    }),
    new Promise((success, fail) => {
      html2canvas(input).then(canvas => {
        success(canvas);
      });
    })
  ]).then((canvases, other) => {
    const header = canvases[0],
      body = canvases[1];
    var pdf = new jsPDF("p", "px", null, true); //({ orientation: 'landscape' });

    pdf.addImage(jnjlogo, "PNG", 25, 3, 50, 9);

    if (headerElement === undefined) {
      pdf.setFontSize(10);
      pdf.text(headerText, 25, 25);
    } else {
      if (draftStatus === true) {
        pdf.setFontSize(12);
        pdf.setTextColor("#c8102e");
        pdf.text(
          "DRAFT VERSION",
          pdf.internal.pageSize.getWidth() / 2 - 40,
          10
        );
        pdf.setTextColor(0, 0, 0);
      }

      var imgHeaderData = header.toDataURL("image/jpg");
      pdf.addImage(
        imgHeaderData,
        "JPG",
        20,
        13,
        pdf.internal.pageSize.getWidth() - 40,
        20
      );
    }
    var imgBodyData = body.toDataURL("image/jpg");
    var height =
      (body.height / body.width) * (pdf.internal.pageSize.getHeight() - 40);
    if (height > pdf.internal.pageSize.getHeight() - 40)
      height = pdf.internal.pageSize.getHeight() - 40;
    pdf.addImage(
      imgBodyData,
      "JPG",
      20,
      35,
      pdf.internal.pageSize.getWidth() - 40,
      height
    );

    var date = new Date();
    // var datesuffix = date.toDateString().substring(4).replaceAll(' ', '-');
    var dtProps = date
      .toDateString()
      .substring(4)
      .split(" ");
    var datesuffix = dtProps[2] + "-" + dtProps[0] + "-" + dtProps[1];

    var fileName = datesuffix + "_" + userID + "_" + exportFileName + ".pdf";

    pdf.save(fileName);

    dispatch(setIsExecutingAction(false));
  });
}
