import React, { useRef, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import {
  Image,
  Row,
  Col,
  Container,
  Navbar,
  NavDropdown,
  Nav
} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import jnjlogo from "../assets/jnj-logo.png";
import "../App.css";
import { getAuthToken } from "../authentication/authToken";
import { fetchUserInformation } from "../actions/userInfo";
import { UserAccess } from "./userAccess";
import {
  fetchAppMessages,
  fetchApplicationMessagesData
} from "../actions/appMessages";
import { setEndUserSessionUuid } from "../actions/endUserActivity";
import _ from "lodash";
import { useMsal } from "@azure/msal-react";

export function Header() {
  const dispatch = useDispatch();

  let { instance } = useMsal();
  const [initialize, setInitialize] = useState(false);
  const [initializeUUID, setInitializeUUID] = useState(false);

  const userInformation = useSelector(state => state.userInformation);
  const pageConfig = useSelector(state => state.appMessages.appMessagePages);

  const userName = useRef("");
  const redirectUrl = useRef("");

  useEffect(() => {
    let token = getAuthToken();
    let userId = token.userEmailId;

    dispatch(fetchUserInformation(userId));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userInformation !== undefined && userInformation.data !== undefined) {
      userName.current =
        userInformation.data.users[0].givenName +
        " " +
        userInformation.data.users[0].sn;
      if (!initializeUUID) {
        dispatch(setEndUserSessionUuid(uuidv4()));
        setInitializeUUID(true);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInformation]);

  useEffect(() => {
    if (!initialize) {
      dispatch(fetchAppMessages());
    }
    setInitialize(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialize]);

  useEffect(() => {
    if (pageConfig !== undefined) {
      let AppMessagePageInfo = _.find(
        pageConfig?.data,
        o => {
          return o.page_name.trim().toLowerCase() === "application messages";
        },
        0
      );

      if (AppMessagePageInfo !== undefined) {
        dispatch(fetchApplicationMessagesData(AppMessagePageInfo.id));
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageConfig]);

  const onCreateNewCalc = function() {
    redirectUrl.current = "/calculatorconfig";
    window.location.href = redirectUrl.current;
  };

  const logoutUser = () => {
    sessionStorage.clear();
    instance.logout();
  };

  return (
    <div className="fmv-header">
      <Container fluid>
        <Row className="align-items-center header-content container">
          <Col md={2} className="text-center jnj-logo">
            <Image src={jnjlogo} fluid className="site-logo"></Image>
          </Col>
          <Col md={2}></Col>
          <Col md={3} className="text-center">
            <LinkContainer to="/">
              <Nav.Link className="nav-title-name">FAIR MARKET VALUE</Nav.Link>
            </LinkContainer>
          </Col>
          <Col></Col>
          <Col md={4} className="text-center fmv-hcc-logo">
            <span className="header-text">HEALTH CARE COMPLIANCE</span>
          </Col>
        </Row>
        <Row className="nav-container align-items-center">
          <Col>
            <Navbar className="nav-bar-items" expand="lg">
              <Container className="navheader-div">
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="me-auto float-right nav-items">
                    <LinkContainer to="/">
                      <Nav.Link className="nav-link-item">Home</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/fmvcalculator">
                      <Nav.Link className="nav-link-item">
                        FMV Calculator
                      </Nav.Link>
                    </LinkContainer>
                    {/* <LinkContainer to="/"><Nav.Link className="nav-Key-Definitions">Key Definitions</Nav.Link></LinkContainer> */}
                    <UserAccess UserRolesPermission="Calculator configuration">
                      <NavDropdown
                        title="Calculator Configuration"
                        id="basic-nav-dropdown"
                        className="Haeder-Navigations"
                      >
                        <UserAccess UserRolesPermission="calculatordashboard">
                          <LinkContainer to="/calculatordashboard">
                            <NavDropdown.Item>
                              View All Calculators
                            </NavDropdown.Item>
                          </LinkContainer>
                        </UserAccess>
                        <UserAccess UserRolesPermission="calculatorconfig">
                          <NavDropdown.Item onClick={onCreateNewCalc}>
                            Create New Calculator
                          </NavDropdown.Item>
                        </UserAccess>
                      </NavDropdown>
                    </UserAccess>
                    <UserAccess UserRolesPermission="Master Data Management">
                      <NavDropdown
                        title="Master Data"
                        id="basic-nav-dropdown"
                        className="Haeder-Navigations"
                      >
                        <UserAccess UserRolesPermission="activity_types">
                          <LinkContainer to="/masterdata/activity_types">
                            <NavDropdown.Item>Activity Type</NavDropdown.Item>
                          </LinkContainer>
                        </UserAccess>
                        {/* <UserAccess UserRolesPermission="variables"><LinkContainer to="/masterdata/variables"><NavDropdown.Item>Common Config Variables</NavDropdown.Item></LinkContainer></UserAccess> */}
                        <UserAccess UserRolesPermission="currencies">
                          <LinkContainer to="/masterdata/currencies">
                            <NavDropdown.Item>Currency</NavDropdown.Item>
                          </LinkContainer>
                        </UserAccess>
                        <UserAccess UserRolesPermission="countries">
                          <LinkContainer to="/masterdata/countries">
                            <NavDropdown.Item>Market</NavDropdown.Item>
                          </LinkContainer>
                        </UserAccess>
                        <UserAccess UserRolesPermission="hcp_specialities">
                          <LinkContainer to="/masterdata/hcp_specialities">
                            <NavDropdown.Item>HCP Specialty</NavDropdown.Item>
                          </LinkContainer>
                        </UserAccess>
                        <UserAccess UserRolesPermission="hcp_speciality_list">
                          <LinkContainer to="/masterdata/hcp_speciality_list">
                            <NavDropdown.Item>
                              HCP Specialty Groups
                            </NavDropdown.Item>
                          </LinkContainer>
                        </UserAccess>
                        <UserAccess UserRolesPermission="hcp_statures">
                          <LinkContainer to="/masterdata/hcp_statures">
                            <NavDropdown.Item>KOL HCP Stature</NavDropdown.Item>
                          </LinkContainer>
                        </UserAccess>
                        <UserAccess UserRolesPermission="hcp_kol_stature_question_defns">
                          <LinkContainer to="/masterdata/hcp_kol_stature_question_defns">
                            <NavDropdown.Item>KOL Definitions</NavDropdown.Item>
                          </LinkContainer>
                        </UserAccess>
                        <UserAccess UserRolesPermission="languages">
                          <LinkContainer to="/masterdata/languages">
                            <NavDropdown.Item>Language</NavDropdown.Item>
                          </LinkContainer>
                        </UserAccess>
                        <UserAccess UserRolesPermission="languages">
                          <LinkContainer to="/languagetranslation">
                            <NavDropdown.Item>
                              Language Translation
                            </NavDropdown.Item>
                          </LinkContainer>
                        </UserAccess>
                        <UserAccess UserRolesPermission="pageconfig">
                          <LinkContainer to="/pageconfig">
                            <NavDropdown.Item>
                              Page Configuration
                            </NavDropdown.Item>
                          </LinkContainer>
                        </UserAccess>
                        <UserAccess UserRolesPermission="percentiles">
                          <LinkContainer to="/masterdata/percentiles">
                            <NavDropdown.Item>Percentile</NavDropdown.Item>
                          </LinkContainer>
                        </UserAccess>
                        <UserAccess UserRolesPermission="reference_categories">
                          <LinkContainer to="/masterdata/reference_categories">
                            <NavDropdown.Item>
                              Reference Category
                            </NavDropdown.Item>
                          </LinkContainer>
                        </UserAccess>
                        <UserAccess UserRolesPermission="regions">
                          <LinkContainer to="/masterdata/regions">
                            <NavDropdown.Item>Region</NavDropdown.Item>
                          </LinkContainer>
                        </UserAccess>
                        <UserAccess UserRolesPermission="sectors">
                          <LinkContainer to="/masterdata/sectors">
                            <NavDropdown.Item>Sector</NavDropdown.Item>
                          </LinkContainer>
                        </UserAccess>
                        <UserAccess UserRolesPermission="segments">
                          <LinkContainer to="/masterdata/segments">
                            <NavDropdown.Item>Segment</NavDropdown.Item>
                          </LinkContainer>
                        </UserAccess>
                        <UserAccess UserRolesPermission="travel_distances">
                          <LinkContainer to="/masterdata/travel_distances">
                            <NavDropdown.Item>Travel Distance</NavDropdown.Item>
                          </LinkContainer>
                        </UserAccess>
                      </NavDropdown>
                    </UserAccess>
                    <UserAccess UserRolesPermission="Master Data Management">
                      <LinkContainer to="/kolassessments">
                        <Nav.Link className="nav-link-item">
                          KOL Assessments
                        </Nav.Link>
                      </LinkContainer>
                    </UserAccess>
                    <UserAccess UserRolesPermission="Master Data Management">
                      <LinkContainer to="/UserRoles">
                        <Nav.Link className="nav-link-item">
                          User Roles
                        </Nav.Link>
                      </LinkContainer>
                    </UserAccess>
                  </Nav>
                </Navbar.Collapse>
                <span className="header-user-name">{userName.current}</span>
                <span
                  className="header-user-name header-logout"
                  onClick={logoutUser}
                >
                  Logout
                </span>
              </Container>
            </Navbar>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
